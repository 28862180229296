<template>
  <div id="homePages" style="background: #f8f8f8">
    <router-view></router-view>

    <div id="navigationBar" v-if="navShow" v-show="!$store.state.isLandscape">
      <!-- <MoreDialog v-if="showMoreDialog" @closeMoreBar="closeMoreBar"></MoreDialog> -->
      <el-row>
        <el-col
          v-for="(nav, i) in navigationRouter"
          :key="nav.id"
          :offset="nav.offset"
          :span="nav.span"
          :class="{ navigationS: i == selectedNavigation }"
        >
          <router-link
            :to="nav.path"
            @click="chageSelect(i)"
            style="display: flex; flex-direction: column; align-items: center; width: 100%"
          >
            <div class="navigationItem" @click="chageSelect(i)">
              <!-- <div class="imageNav"> -->
              <img v-if="i == 0 && i == selectedNavigation" :src="bloodU" style="width: 0.16rem; height: 0.21rem" />
              <img v-if="i == 0 && i != selectedNavigation" :src="blood" style="width: 0.16rem; height: 0.21rem" />
              <img v-if="i == 1 && i == selectedNavigation" :src="reportU" style="width: 0.2rem; height: 0.2rem" />
              <img v-if="i == 1 && i != selectedNavigation" :src="report" style="width: 0.2rem; height: 0.2rem" />
              <img v-if="i == 2 && i == selectedNavigation" :src="shopU" style="width: 0.21rem; height: 0.2rem" />
              <img v-if="i == 2 && i != selectedNavigation" :src="shop" style="width: 0.21rem; height: 0.2rem" />
              <img v-if="i == 3 && i == selectedNavigation" :src="mineU" style="width: 0.18rem; height: 0.19rem" />
              <img v-if="i == 3 && i != selectedNavigation" :src="mine" style="width: 0.18rem; height: 0.19rem" />
            </div>
            <!-- <div v-if="i == 2" class="moreNav">
                <i class="el-icon-plus"></i>
              </div> -->
            <!-- </div> -->
            <div
              style="color: #292929; font-size: 0.1rem"
              :class="{ active: selectedNavigation == i }"
              @click="chageSelect(i)"
            >
              {{ nav.name }}
            </div>
          </router-link>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  // import { Toast } from 'vant'
  import MoreDialog from '../moreDialog/index.vue'
  import blood from '../../static/pic/blood.png'
  import report from '../../static/pic/report.png'
  import recover from '../../static/pic/recover.png'
  import mine from '../../static/pic/mine.png'
  import bloodU from '../../static/pic/bloodU.png'
  import reportU from '../../static/pic/reportU.png'
  import recoverU from '../../static/pic/recoverU.png'
  import mineU from '../../static/pic/mineU.png'
  import shop from '../../static/pic/shop.png'
  import shopU from '../../static/pic/shopU.png'
  export default {
    name: 'homePages',
    components: {
      MoreDialog,
    },
    data() {
      return {
        navShow: true,
        showCGMDia: false,
        showIframe: false,
        showGJEmpower: false,
        hadBindCGM: false,
        hadLogin: false,
        showDoalog: false,
        bindingCGM: {},
        noCGM: true,
        blood: blood,
        report: report,
        recover: recover,
        mine: mine,
        bloodU: bloodU,
        reportU: reportU,
        recoverU: recoverU,
        mineU: mineU,
        shop: shop,
        shopU: shopU,
        selectedNavigation: 0,
        showMoreDialog: false,
        navigationRouter: [
          {
            name: '监测',
            path: '/BloodSugar',
            image: '',
            flag: true,
            icon: '',
            width: 20,
            key: '',
            id: 'blood',
            span: 5,
            offset: 0,
          },
          {
            name: '统计',
            path: '/ReportCenter',
            image: '',
            flag: true,
            icon: '',
            width: 20,
            key: '',
            id: 'report',
            span: 5,
            offset: 0,
          },
          // {
          //   name: ' ',
          //   path: '',
          //   image: '',
          //   flag: true,
          //   icon: '',
          //   width: 20,
          //   key: '',
          //   id: 'more',
          //   span: 4,
          //   offset: 0,
          // },
          // {
          //   name: "关注",
          //   path: "/RecordDetail",
          //   image: "",
          //   flag: true,
          //   icon: "",
          //   width: 20,
          //   key: "",
          //   id: "record",
          //   span: 4,
          //   offset: 0,
          // },
          {
            name: '商城',
            path: '/shop',
            image: '',
            flag: true,
            icon: '',
            width: 20,
            key: '',
            id: 'shop',
            span: 5,
            offset: 0,
          },
          {
            name: '我的',
            path: '/MineView',
            image: '',
            flag: true,
            icon: '',
            width: 20,
            key: '',
            id: 'mine',
            span: 5,
            offset: 0,
          },
        ],
        startX: '',
        startY: '',
        endX: '',
        endY: '',
        slideX: '',
        slideY: '',
        startTime: '',
        endTime: '',
        slideTime: '',
      }
    },
    watch: {
      $route: {
        handler(val, oldValue) {
          if (val.query.index == 0) {
            this.selectedNavigation = 0
          }
          if (this.$route.path == '/BloodSugar') {
            this.selectedNavigation = 0
            this.navShow = true
          } else if (this.$route.path == '/ReportCenter') {
            this.selectedNavigation = 1
            this.navShow = true
          } else if (this.$route.path == '/MineView') {
            this.selectedNavigation = 3
            this.navShow = true
          } else {
            this.navShow = false
          }
        },
        immediate: true, //先执行一遍
      },
    },
    mounted() {
      document.addEventListener('click', e => {
        const moreBar = document.querySelector('.moreBar')
        const moreNav = document.querySelector('.moreNav')
        if (moreBar && moreNav) {
          if (!moreBar.contains(e.target) && !moreNav.contains(e.target)) {
            this.showMoreDialog = false
          }
        }
      })
      this.checkLogin()

      window.transferWait = this.transferWait
      window.transferWaitAndroid = this.transferWaitAndroid
    },

    methods: {
      touchStart(e) {
        this.startX = e.touches[0].pageX
        this.startY = e.touches[0].clientY
        this.startTime = new Date().getTime()
      },
      touchEnd(e) {
        console.log(this.$route.path, 'route.path')
        this.endX = e.changedTouches[0].pageX
        this.endY = e.changedTouches[0].clientY
        this.endTime = new Date().getTime()
        this.slideTime = this.endTime - this.startTime
        this.slideX = this.endX - this.startX
        this.slideY = Math.abs(this.endY - this.startY)
        if (
          this.slideX > 10 &&
          this.slideTime < 500 &&
          this.slideX > this.slideY &&
          this.$route.path != '/BloodSugar' &&
          this.$route.path != '/ReportCenter' &&
          this.$route.path != '/RecordDetail' &&
          this.$route.path != '/MineView' &&
          this.$route.path != '/login' &&
          this.$route.path != '/passwordLogin' &&
          this.$route.path != '/baiban' &&
          this.$route.path != '/waitingB' &&
          this.$route.path != '/waitingA' &&
          this.$route.path != '/nsPage'
        ) {
          this.$router.go(-1)
        }
      },
      //接收共享音频
      transferWait(val) {
        this.$router.push({
          path: 'waitingB',
          query: {
            baibanId: val.parameter.data,
            name: val.parameter.name,
            img: val.parameter.img,
          },
        })
      },
      transferWaitAndroid(info) {
        const val = JSON.parse(JSON.stringify(info))
        // if (val.callTime >= 30000) {
        //   this.$router.push("/BloodSugar");
        // } else {
        this.$router.push({
          path: 'waitingB',
          query: {
            baibanId: val.data,
            name: val.name,
            img: val.img,
            callTime: val.callTime,
          },
        })
        // }
        return '确定'
      },
      openGJEmpower() {
        this.showIframe = true
        this.showCGMDia = false
        this.showGJEmpower = true
      },
      closeGJEmpower() {
        this.showIframe = false
        this.showGJEmpower = false
      },
      chageSelect(i) {
        const date = localStorage.getItem('clickFBI')
        if (!date) {
          localStorage.setItem('clickFBI', new Date())
        } else {
          if (new Date() - date <= 2000) {
            localStorage.setItem('clickFBI', new Date())
            return
          } else {
            localStorage.setItem('clickFBI', new Date())
          }
        }
        if (i !== 2) {
          this.selectedNavigation = i
        } else {
          this.showMoreDialog = true
          this.showDoalog = true
        }
      },
      closeMoreBar() {
        this.showMoreDialog = false
        this.showDoalog = false
      },
      checkLogin() {
        const loginStatus = localStorage.getItem('loginStatus')
        if (loginStatus && loginStatus === 'true') {
          this.hadLogin = true
        } else {
          console.log(this.$route.path, 'path')
          setTimeout(() => {
            if (this.$route.path == '/addPatient') {
              console.log('不走了')
            } else {
              this.$router.push('/login')
            }
          }, 300)
        }
      },
      openCGMDia() {
        this.showCGMDia = true
      },
      colseCGMView() {
        this.showCGMDia = false
      },
      selectThecGM(bindingCGM) {
        this.bindingCGM = bindingCGM
        this.noCGM = false
        this.hadBindCGM = true
      },
      editBindingCGM(device) {
        this.bindingCGM = device
      },
    },
  }
</script>
<style scoped lang="less">
  #navigationBar {
    height: 0.62rem;
    background: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 99;
    box-shadow: 0px -1px 3px 0px rgba(242, 242, 242, 0.65);
  }
  .el-row {
    height: 100%;
  }
  .el-col {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;
  }
  .navigationItem {
    width: 100%;
    margin-bottom: 0.05rem;
    img {
      width: 0.2rem;
      height: 0.2rem;
    }
  }
  #homePage {
    position: relative;
  }
  .active {
    color: #009944 !important;
  }
</style>
