<template>
  <div class="index">
    <div class="time">{{ leftTime }}</div>
    <div class="top">
      <div class="top-left">
        <img src="@/static/pic/ttLogo.png" style="margin: 0 0.08rem 0 0.17rem" />
        <span>理糖宝</span>
      </div>
      <div class="top-right">
        <img src="@/static/pic/lingDang.png" style="height: 0.22rem; width: 0.19rem" @click="openAlarmPage()" />
        <img
          src="@/static/pic/tianJia.png"
          @click="popupShow = !popupShow"
          style="margin: 0 0.17rem 0 0.12rem; height: 0.22rem; width: 0.22rem"
        />
      </div>
    </div>
    <div class="bigImg">
      <div class="tip" @click="$router.push('trEvent')">血糖列表</div>
      <img src="@/static/pic/container.png" class="img" />
      <div class="sugar">
        <span>
          <div>{{ nowdata1 == 'NaN' ? '-' : nowdata1 }}</div>
          <s>•</s>
          <i>{{ nowdata2 == 'NaN' ? '-' : nowdata2 }}</i>
          <img
            src="@/static/pic/shot1.png"
            v-if="trend === 0"
            style="width: 0.21rem; height: 0.1rem; margin-bottom: 0.07rem"
          />
          <img src="@/static/pic/shot2.png" v-else-if="trend == 3" />
          <img src="@/static/pic/shot3.png" v-else-if="trend == 4" />
          <img
            src="@/static/pic/shot1.png"
            v-else-if="trend == 1"
            class="rote-45"
            style="width: 0.21rem; height: 0.1rem; margin-bottom: 0.07rem"
          />
          <img
            src="@/static/pic/shot1.png"
            v-else-if="trend == 2"
            class="rote45"
            style="width: 0.21rem; height: 0.1rem; margin-bottom: 0.07rem"
          />
        </span>
        <b>mmol/L</b>
      </div>
    </div>
    <Wave style="margin-top: -1px" />
    <div class="bacc"></div>
    <div class="sugarPare">
      <span v-show="flag">两日血糖对比</span>
    </div>
    <div class="btn flex">
      <span>近2日对比</span>
      <van-switch v-model="flag" @change="openTwoDays()" active-color="#009944" inactive-color="#8f8f8f"></van-switch>
    </div>
    <Test @getPercent="getPercent" v-if="!flag" @getTrend="getTrend" />
    <TwoDays v-else />
    <div class="addBtn" v-if="qualifyTime > 20" @click="openCGMDia()">
      <img src="@/static/pic/addBtn.png" alt="" />
      <span>添加血糖仪</span>
    </div>
    <Panel
      @openCGMDia="openCGMDia()"
      :cgmTypeName="cgmTypeName"
      :percentage="percentage"
      :leaveTime="leaveTime"
      v-else
    />

    <!-- CGM授权弹出层 -->
    <van-popup v-if="showCGMDia" v-model="showCGMDia" position="bottom" style="padding-bottom: 0.3rem">
      <CGMView @clickCgm="clickCgm()"></CGMView>
    </van-popup>

    <!-- 理糖宝事件弹出层 -->
    <van-popup v-model="popupShow" class="event">
      <span @click="openCamera()">
        <img src="@/static/pic/qrShow.png" />
        <i>扫一扫</i>
      </span>
      <span @click="openDialog('ydsView')" style="margin-top: 0.06rem">
        <img src="@/static/pic/yidaosu.png" />
        <i>胰岛素</i>
      </span>
      <span @click="openDialog('tsView')">
        <img src="@/static/pic/tanshui.png" />
        <i>碳水化合物</i>
      </span>
      <span @click="openDialog('kfyView')">
        <img src="@/static/pic/yaowu.png" />
        <i>口服药</i>
      </span>
      <span @click="openDialog('ydView')">
        <img src="@/static/pic/yundong.png" />
        <i>运动</i>
      </span>
      <span @click="openDialog('zxView')">
        <img src="@/static/pic/xuetang.png" />
        <i style="border-bottom: 0">指尖血</i>
      </span>
    </van-popup>
  </div>
</template>

<script>
  import Panel from '@/pages/bloodSugar/component/panel.vue'
  import Wave from '@/pages/bloodSugar/component/wave.vue'
  import Test from '@/pages/bloodSugar/test.vue'
  import TwoDays from '@/pages/bloodSugar/component/twoDaySugar.vue'
  import html2canvas from 'html2canvas'
  import { Toast, Dialog } from 'vant'
  import image1 from '../../static/pic/image1.png'
  import image2 from '../../static/pic/image2.png'
  import image3 from '../../static/pic/image3.png'
  import image4 from '../../static/pic/image4.png'
  import image5 from '../../static/pic/share.png'
  import image6 from '../../static/pic/image6.png'
  import ypImage from '../../static/cgm/yp.png'
  import dkImage from '../../static/cgm/dk.png'
  import wwImage from '../../static/cgm/ww.png'
  import gjImage from '../../static/cgm/gj.png'
  import select from '../../static/pic/select.png'
  import unselect from '../../static/pic/unselect.png'
  import doctor from '../../static/pic/doctor.png'
  import addCalibration from './component/addCalibration.vue'
  import CGMView from './component/CGMView.vue'
  import CGMDetails from './component/CGMDetails.vue'
  import LoopDetails from './component/loopDetails.vue'
  import { insertbg, getBGbyToken, getNew } from '../../api/userServices'
  import { loopDevicestatus, getUserInfoById } from '../../api/relationship'
  import { dateCompute } from '../sugerData/getSugerData'
  export default {
    components: { CGMDetails, addCalibration, CGMView, LoopDetails, Test, Wave, Panel, TwoDays },
    data() {
      return {
        // 闭环设备信息
        showRuler: true,
        cgmType: JSON.parse(localStorage.getItem('appuser')).cgm,
        loopDevice: {},
        showCGMDia: false,
        nowdata1: '-',
        nowdata2: '-',
        image1: image1,
        image2: image2,
        image3: image3,
        image4: image4,
        image5: image5,
        image6: image6,
        ypImage: ypImage,
        dkImage: dkImage,
        wwImage: wwImage,
        gjImage: gjImage,
        doctor: doctor,
        select: select,
        showButtonPop: false,
        unselect: unselect,
        selectedDate: '3H',
        bloodSugerValue: {
          slopeOrdinal: 0,
          calculatedValueAsString1: ' ',
          calculatedValueAsString2: ' ',
          nowDate: '',
        },
        timer: null,
        qualifyTime: '',
        leaveTime: '',
        version: localStorage.getItem('version'),
        loopTime: '',
        cgmTypeName: null,
        popupShow: false,
        percentage: '',
        isTwoDays: false,
        flag: false,
        trend: '',
        leftTime: '',
      }
    },
    created() {
      this.searchNewSugerBlood()
      if (this.cgmType == 'loop' && this.version == 'loop') {
        this.getLoopStatus()
      }
    },
    mounted() {
      window.unifiedInterFace = this.unifiedInterFace
      window.waitIOSCamera = this.waitIOSCamera
      window.waitAndroidCamera = this.waitAndroidCamera

      window.setRealtimeBlood = this.setRealtimeBlood
      window.setAndroidRealtimeBlood = this.setAndroidRealtimeBlood
      window.waitIOSSetGJValue = this.waitIOSSetGJValue
      window.waitAndroidSetGJValue = this.waitAndroidSetGJValue
      window.saveImageToBlob = this.saveImageToBlob
      window.openLibreNFC = this.openLibreNFC
      this.timer = setInterval(() => {
        this.searchNewSugerBlood()
        if (this.cgmType == 'loop' && this.version == 'loop') {
          this.getLoopStatus()
        }
      }, 2 * 60 * 1000)
      this.$once('hook:beforeDestroy', () => {
        // 页面关闭
        console.log('关闭最后血糖定时器')
        clearInterval(this.timer) // 停止
      })
    },
    methods: {
      // 扫一扫
      openCamera() {
        const message = {
          message: '扫描二维码',
          body: {},
        }
        const androidMessage = JSON.stringify(message)
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.openCamera(androidMessage)
        } else {
          window.webkit.messageHandlers.openCamera.postMessage(message)
        }
      },
      getTrend(val1, val2) {
        this.trend = val1
        this.leftTime =
          ((this.$moment().valueOf() - this.$moment(val2).valueOf()) / 1000 / 60).toFixed(0) + '分钟前更新'
      },
      openTwoDays() {},
      openDialog(type) {
        this.$router.push('/ReportCenter/' + type)
      },
      getPercent(num) {
        this.percentage = num
      },
      // 刷新血糖
      refresh() {
        this.searchNewSugerBlood()
        if (this.cgmType == 'loop' && this.version == 'loop') {
          this.getLoopStatus()
        }
      },
      //接收更新血糖
      unifiedInterFace() {
        this.searchNewSugerBlood()
        if (this.cgmType == 'loop' && this.version == 'loop') {
          this.getLoopStatus()
        }
        // 更新用户信息
        const id = JSON.parse(localStorage.getItem('appuser')).id
        getUserInfoById(id).then(res => {
          const newData = {
            ...res.data.data,
            loopRemote: JSON.parse(localStorage.getItem('appuser')).loopRemote,
          }
          localStorage.setItem('appuser', JSON.stringify(newData))
        })
        return '确定'
      },
      clickCgm(val) {
        this.showCGMDia = val
      },
      // 扫一扫
      openCamera() {
        const message = {
          message: '扫描二维码',
          body: {},
        }
        const androidMessage = JSON.stringify(message)
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.openCamera(androidMessage)
        } else {
          window.webkit.messageHandlers.openCamera.postMessage(message)
        }
      },
      waitIOSCamera(codeInfo) {
        console.log(codeInfo, 'codeInfo')
        if (codeInfo.qrcType == 1) {
          Toast('您扫描的不是医生二维码！')
        } else if (codeInfo.qrcType == 2) {
          // 患者扫医生码加入医院并和这个医生绑定关系
          this.$router.push({
            path: 'bindingInfo',
            query: codeInfo,
          })
        } else if (
          codeInfo.qrcType == 3 || // 医生扫医院码加入医院
          codeInfo.qrcType == 4 || // 患者扫医院码加入医院
          codeInfo.qrcType == 5 // 患者扫医院码加入医院并加入护士站
        ) {
          console.log(codeInfo.qrcType, 'qrcType')
          this.$router.push({
            path: 'joinHospital',
            query: codeInfo,
          })
        }
      },
      waitAndroidCamera(info) {
        const codeInfo = JSON.parse(JSON.stringify(info))
        if (codeInfo.qrcType == 1) {
          Toast('您扫描的不是医生二维码！')
        } else if (codeInfo.qrcType == 2) {
          // 患者扫医生码加入医院并和这个医生绑定关系
          this.$router.push({
            path: 'bindingInfo',
            query: codeInfo,
          })
        } else if (
          codeInfo.qrcType == 3 || // 医生扫医院码加入医院
          codeInfo.qrcType == 4 || // 患者扫医院码加入医院
          codeInfo.qrcType == 5 // 患者扫医院码加入医院并加入护士站
        ) {
          console.log(codeInfo.qrcType, 'qrcType')
          this.$router.push({
            path: 'joinHospital',
            query: codeInfo,
          })
        }
        return '确定'
      },
      goloop() {
        const id = JSON.parse(localStorage.getItem('appuser')).id
        this.$router.push({
          path: 'loopDetails',
          query: { id: id },
        })
      },
      // 获取闭环设备信息
      getLoopStatus() {
        const id = JSON.parse(localStorage.getItem('appuser')).id
        loopDevicestatus({ uid: id }).then(res => {
          this.loopDevice = res.data
          if (res.data.create_time) {
            // 计算loopd断开时间
            const start = res.data.create_time
            const end = this.$moment().format('YYYY-MM-DD HH:mm:ss')
            // this.qualifyTime = calculateMinutes(start, end);

            this.loopTime = this.$moment(end).diff(this.$moment(start), 'minutes')
          }
        })
      },
      // 图片格式转换方法
      dataURLToBlob(dataurl) {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new Blob([u8arr], { type: mime })
      },
      // 截屏方法
      saveImage(divText, imgText) {
        const phoneType = localStorage.getItem('phoneType')

        const canvasID = this.$refs[divText]
        console.log(canvasID, 'canvasID')
        html2canvas(canvasID).then(canvas => {
          const img = canvas.toDataURL('image/jpeg', 0.8).slice(23)
          console.log(img, 'img')
          const message = {
            message: '图片分享',
            body: {
              blob: img,
              type: 'home',
            },
          }
          console.log(message, '要传递的数据')
          const androidMessage = JSON.stringify(message)
          if (phoneType == 'Android') {
            window.android.saveImageToBlob(androidMessage)
          } else {
            window.webkit.messageHandlers.saveImageToBlob.postMessage(message)
          }
        })
      },
      openCalibration() {
        this.showButtonPop = true
      },
      openAlarmPage() {
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.openNotification()
        }
        this.$router.push('BloodSugar/alarmCFG')
      },
      waitIOSSetGJValue(value) {
        this.setBGToJS(value.v)
        this.setSlopeToJS(value.s)
        this.nowdata1 = (value.v + '').split('.')[0] ? (value.v + '').split('.')[0] : '0'
        this.nowdata2 = (value.v + '').split('.')[1] ? (value.v + '').split('.')[1] : '0'
        this.nowDate = value.t
      },
      waitAndroidSetGJValue(info) {
        const value = JSON.parse(JSON.stringify(info))
        this.setBGToJS(value.v)
        this.setSlopeToJS(value.s)
        this.nowdata1 = (value.v + '').split('.')[0] ? (value.v + '').split('.')[0] : '0'
        this.nowdata2 = (value.v + '').split('.')[1] ? (value.v + '').split('.')[1] : '0'
        this.nowDate = value.t

        return '确定'
      },
      searchNewSugerBlood() {
        getNew({}).then(res => {
          if (res.data.code === '200' && res.data.data) {
            switch (res.data.data.cgm) {
              case 'ns':
                this.cgmTypeName = 'nightscout'
                break
              case 'aaps':
                this.cgmTypeName = 'aaps'
                break
              case 'loop':
                this.cgmTypeName = 'loop'
                break
              case 'sg':
                this.cgmTypeName = '雅培瞬感'
                break
              case 'yp':
                this.cgmTypeName = '雅培旺旺'
                break
              case 'ww':
                this.cgmTypeName = '雅培旺旺'
                break
              case 'gj':
                this.cgmTypeName = '硅基动感'
                break
              case 'sn':
                this.cgmTypeName = '三诺爱看'
                break

              default:
                break
            }
            // this.trend = res.data.data.direction
            // 计算达标时间
            const start = res.data.data.create_time
            const end = this.$moment().format('YYYY-MM-DD HH:mm:ss')
            // this.qualifyTime = calculateMinutes(start, end);

            this.qualifyTime = this.$moment(end).diff(this.$moment(start), 'minutes')

            // 计算传感器剩余时间
            if (res.data.data.enableTime) {
              const enableTime = this.$moment(res.data.data.enableTime).format('YYYY-MM-DD HH:mm:ss')
              const lastTime = this.$moment().format('YYYY-MM-DD HH:mm:ss')
              this.leaveTime = dateCompute(enableTime, lastTime)
            }
            const sgv = parseFloat(res.data.data.v).toFixed(1)
            const sugarTime = this.$moment(res.data.data.create_time).valueOf()
            const now = this.$moment().valueOf()
            const time = (now - sugarTime) / 1000 / 60
            this.setBGToJS(sgv)
            this.setSlopeToJS(res.data.data.s)
            this.cgmType = res.data.data.cgm

            if (time < 30) {
              this.nowdata1 = sgv.split('.')[0] ? sgv.split('.')[0] : '0'
              this.nowdata2 = sgv.split('.')[1] ? sgv.split('.')[1] : '0'
            } else {
              this.$toast('当前血糖值未获取')
            }

            // cgm过期时间，雅培和旺旺没有传，暂时引掉
            // if (res.data.data.cgm == "ns" || res.data.data.cgm == "loop") {
            //   this.nowdata1 = sgv.split(".")[0];
            //   this.nowdata2 = sgv.split(".")[1] ? sgv.split(".")[1] : "0";
            // } else {
            //   this.nowdata1 = this.leaveTime <= 0 ? "0" : sgv.split(".")[0];
            //   this.nowdata2 =
            //     this.leaveTime <= 0
            //       ? "0"
            //       : sgv.split(".")[1]
            //       ? sgv.split(".")[1]
            //       : "0";
            // }

            this.bloodSugerValue.nowDate = res.data.data.t
            if (res.data.data.cgm == 'gj') {
              this.changeBloodSugerGjStyle(res.data.data.s)
            } else if (res.data.data.cgm == 'yp' || res.data.data.cgm == 'sg') {
              this.changeBloodSugerYpStyle(res.data.data.s)
            }

            // const date = this.getFormatDate(res.data.data.t);
            // switch (appuser.cgm) {
            //   case 'gj':
            //   Toast("硅基CGM: " + "时间:" + date);
            //     break;
            //   case 'yp':
            //   Toast("雅培CGM: " + "时间:" + date);
            //     break;
            //   case 'sg':
            //   Toast("瞬感NFC: " + "时间:" + date);
            //     break;
            //   case 'dk':
            //   Toast("德康G6: " + "时间:" + date);
            //     break;
            //   default:
            //     break;
            // }
          } else {
            this.qualifyTime = 21
            if (this.$route.path == '/login' || this.$route.path == '/passwordLogin') {
              return
            } else {
              Toast('获取数据失败,等待CGM推送')
            }
          }
        })
      },
      setBGToJS(bguger) {
        localStorage.setItem('bloodSuger', bguger)
      },
      setSlopeToJS(slope) {
        localStorage.setItem('slopeOrdinal', slope)
      },
      editBindingCGM(device) {
        this.$emit('editBindingCGM', device)
      },
      openCGMDia() {
        this.showCGMDia = true
      },
      // ios端主动调用更新血糖数据
      setRealtimeBlood(info) {
        // 斜率
        const slopeOrdinal = info.slopeOrdinal
        //   血糖
        // const calculatedValueAsString = info.calculatedValueAsString
        const bgValueRounded = info.bgValueRounded
        // 时间戳
        const nowDate = this.getFormatDate(new Date().valueOf(info.nowDate))
        // const sgv = (parseFloat(calculatedValueAsString) / 18).toFixed(1);
        const sgv = bgValueRounded
        this.setBGToJS(sgv)
        this.setSlopeToJS(slopeOrdinal)
        this.bloodSugerValue.slopeOrdinal = slopeOrdinal
        this.nowdata1 = sgv.split('.')[0] ? sgv.split('.')[0] : '0'
        this.nowdata2 = sgv.split('.')[1] ? sgv.split('.')[1] : '0'
        this.bloodSugerValue.nowDate = nowDate
        // this.changeBloodSugerImgStyle(slopeOrdinal);
        const params = {
          sgv: info.bgValueRounded,
          localTime: nowDate,
          slope: slopeOrdinal,
        }
        insertbg(params).then(res => {
          if (res.data.code === '2002') {
            const newData = {
              ...res.data.data.appuser,
              loopRemote: JSON.parse(localStorage.getItem('appuser')).loopRemote,
            }
            localStorage.setItem('token', res.data.data.token)
            localStorage.setItem('appuser', JSON.stringify(newData))
            localStorage.setItem('loginStatus', true)
            this.$emit('checkLogin')
          }
        })
      },
      // android端主动调用更新血糖数据
      setAndroidRealtimeBlood(data) {
        const info = JSON.parse(JSON.stringify(data))
        // 斜率
        const slopeOrdinal = info.slopeOrdinal
        //   血糖
        // const calculatedValueAsString = info.calculatedValueAsString
        const bgValueRounded = info.bgValueRounded
        // 时间戳
        const nowDate = this.getFormatDate(new Date().valueOf(info.nowDate))
        // const sgv = (parseFloat(calculatedValueAsString) / 18).toFixed(1);
        const sgv = bgValueRounded
        this.setBGToJS(sgv)
        this.setSlopeToJS(slopeOrdinal)
        this.bloodSugerValue.slopeOrdinal = slopeOrdinal
        this.nowdata1 = sgv.split('.')[0] ? sgv.split('.')[0] : '0'
        this.nowdata2 = sgv.split('.')[1] ? sgv.split('.')[1] : '0'
        this.bloodSugerValue.nowDate = nowDate
        // this.changeBloodSugerImgStyle(slopeOrdinal);
        const params = {
          sgv: info.bgValueRounded,
          localTime: nowDate,
          slope: slopeOrdinal,
        }
        insertbg(params).then(res => {
          if (res.data.code === '2002') {
            const newData = {
              ...res.data.data.appuser,
              loopRemote: JSON.parse(localStorage.getItem('appuser')).loopRemote,
            }
            localStorage.setItem('token', res.data.data.token)
            localStorage.setItem('appuser', JSON.stringify(newData))
            localStorage.setItem('loginStatus', true)
            this.$emit('checkLogin')
          }
        })
        return '确定'
      },
      // 硅基血糖指向
      changeBloodSugerGjStyle(slopeOrdinal) {
        if (parseInt(slopeOrdinal) === -2) {
          document.getElementById('bloodSugerImg').style.transform = 'rotate(45deg)'
        } else if (parseInt(slopeOrdinal) === -1) {
          document.getElementById('bloodSugerImg').style.transform = 'rotate(22deg)'
        } else if (parseInt(slopeOrdinal) === 0) {
          document.getElementById('bloodSugerImg').style.transform = 'rotate(0deg)'
        } else if (parseInt(slopeOrdinal) === 1) {
          document.getElementById('bloodSugerImg').style.transform = 'rotate(-22deg)'
        } else if (parseInt(slopeOrdinal) === 2) {
          document.getElementById('bloodSugerImg').style.transform = 'rotate(-45deg)'
        }
      },
      // 雅培血糖指向
      changeBloodSugerYpStyle(slopeOrdinal) {
        if (parseInt(slopeOrdinal) === 7) {
          document.getElementById('bloodSugerImg').style.transform = 'rotate(67deg)'
        } else if (parseInt(slopeOrdinal) === 6) {
          document.getElementById('bloodSugerImg').style.transform = 'rotate(45deg)'
        } else if (parseInt(slopeOrdinal) === 5) {
          document.getElementById('bloodSugerImg').style.transform = 'rotate(22deg)'
        } else if (parseInt(slopeOrdinal) === 4) {
          document.getElementById('bloodSugerImg').style.transform = 'rotate(0deg)'
        } else if (parseInt(slopeOrdinal) === 3) {
          document.getElementById('bloodSugerImg').style.transform = 'rotate(-22deg)'
        } else if (parseInt(slopeOrdinal) === 2) {
          document.getElementById('bloodSugerImg').style.transform = 'rotate(-45deg)'
        } else if (parseInt(slopeOrdinal) === 1) {
          document.getElementById('bloodSugerImg').style.transform = 'rotate(67deg)'
        }
      },
      // 跳转到cgm连接页
      openCgmTojs() {
        const cgmType = JSON.parse(localStorage.getItem('appuser')).cgm
        if (cgmType == 'gj') {
          this.$router.push('/BloodSugar/GJEmpower')
        } else if (cgmType == 'yp') {
          this.$router.push('/BloodSugar/wangEmitter')
        } else if (cgmType == 'sg') {
          this.openLibreNFC()
        } else if (cgmType == 'sn') {
          this.$router.push('/BloodSugar/SNEmitter')
        } else {
          return
        }
      },
      // 发射雅培
      openLibreNFC() {
        const message = {
          message: '发射雅培',
          body: {
            type: 'LibreNFC',
          },
        }
        const androidMessage = JSON.stringify(message)
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.openLibreNFC(androidMessage)
        } else {
          window.webkit.messageHandlers.openLibreNFC.postMessage(message)
        }
      },
      getFormatDate(time) {
        const date = new Date(parseInt(time))
        const year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let hour = date.getHours()
        let minutes = date.getMinutes()
        let seconds = date.getSeconds()
        month = month < 10 ? '0' + month : month
        day = day < 10 ? '0' + day : day
        hour = hour < 10 ? '0' + hour : hour
        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds
        const currentDate = year + '-' + month + '-' + day + ' ' + hour + ':' + minutes + ':' + seconds
        return currentDate
      },
    },
  }
</script>

<style scoped lang="less">
  .index {
    background: #fff;
    padding-bottom: 0.7rem;
    .top {
      background-color: #009944;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 0.62rem;
      color: #fff;
      .top-left {
        display: flex;
        align-items: center;
      }
      img {
        width: 0.19rem;
      }
    }
    .bigImg {
      background-color: #009944;
      position: relative;
      height: 1.4rem;
      margin-top: -1px;
      .tip {
        position: absolute;
        left: 0.17rem;
        top: 0;
        width: 0.79rem;
        height: 0.24rem;
        line-height: 0.24rem;
        background-color: #f3faf7;
        font-size: 0.12rem;
        color: #009944;
        border-radius: 13px;
      }
      .img {
        position: absolute;
        top: 0.25rem;
        left: 1.09rem;
        width: 1.57rem;
        height: 1.57rem;
      }
      .sugar {
        position: absolute;
        top: 0.44rem;
        left: 1.28rem;
        width: 1.19rem;
        height: 1.19rem;
        border-radius: 50%;
        color: #fff;
        span {
          position: absolute;
          top: 0.33rem;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          align-items: flex-end;
          height: 0.4rem;
          line-height: 0.4rem;
          font-size: 0.52rem;
          text-align: center;
          img {
            margin-left: 0.06rem;
            margin-bottom: 0.02rem;
            width: 0.1rem;
            height: 0.2rem;
          }
          s {
            line-height: 0.14rem;
            font-size: 0.24rem;
          }
          i {
            font-size: 0.28rem;
            line-height: 0.23rem;
          }
        }
        // img {
        //   position: absolute;
        //   width: 0.1rem;
        //   height: 0.2rem;
        //   left: 0.92rem;
        //   top: 0.5rem;
        // }
        b {
          position: absolute;
          left: 0.34rem;
          top: 0.81rem;
          font-size: 0.15rem;
        }
      }
    }
    .bacc {
      position: relative;
      z-index: 1;
      margin-top: -2px;
      height: 0.4rem;
      background: linear-gradient(to bottom, #9fd8b9, #fff);
    }
    .sugarPare {
      position: absolute;
      top: 3.17rem;
      left: 0.13rem;
      font-size: 0.16rem;
      z-index: 2;
    }
    .addBtn {
      margin: 0.28rem auto 0.2rem;
      width: 3.21rem;
      height: 0.45rem;
      background: #009944;
      box-shadow: 0px 3px 10px 0px rgba(0, 153, 68, 0.3);
      border-radius: 0.22rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-right: 0.12rem;
        width: 0.16rem;
        height: 0.16rem;
      }
      span {
        color: #fff;
      }
    }
    .event {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 1.9rem;
      height: 2.85rem;
      left: 2.85rem;
      width: 1.64rem;
      border-radius: 0.03rem;
      span {
        display: flex;
        align-items: center;
        width: 1.64rem;
        line-height: 0.45rem;
        text-align: center;
        font-size: 0.14rem;
        color: #000;

        i {
          text-align: left;
          width: 1.03rem;
          font-style: normal;
          border-bottom: 1px solid #cecdce;
        }
        img {
          margin: 0 0.14rem;
          width: 0.32rem;
          height: 0.32rem;
        }
      }
    }
  }
  .btn {
    position: absolute;
    z-index: 99;
    right: 0.2rem;
    top: 3.5rem;
    span {
      font-size: 0.14rem;
      margin-right: 0.06rem;
    }
    ::v-deep .van-switch {
      width: 0.42rem;
      height: 0.2rem;
      .van-switch__node {
        width: 0.2rem;
        height: 0.2rem;
        box-shadow: none;
      }
    }
    ::v-deep .van-switch--on {
      .van-switch__node {
        transform: translateX(0.22rem);
        // -webkit-transform: translateX(0.1rem);
      }
    }
  }
  .time {
    position: absolute;
    right: 0.2rem;
    top: 0.7rem;
    z-index: 1;
    color: #fff;
    font-size: 0.12rem;
  }
  .rote-45 {
    transform: rotate(-60deg);
  }
  .rote45 {
    transform: rotate(60deg);
  }
</style>
