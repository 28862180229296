<template>
  <div>
    <div :id="id" />
  </div>
</template>

<script>
  import * as echarts from 'echarts'

  export default {
    name: 'VolumeMap',
    props: {
      distributionData: {
        type: [Object, Array, String],
      },
      id: {
        type: String,
      },
    },
    data() {
      return {
        chart: null,
      }
    },
    watch: {
      // distributionData: {
      //   handler: function (newVal, oldVal) {
      //     if (oldVal) {
      //       this.$nextTick(() => {
      //         this.initChart()
      //       })
      //     }
      //   },
      // },
    },
    mounted() {
      // setTimeout(() => {
      //   this.initChart()
      // }, 5000)
    },
    beforeDestroy() {
      if (!this.chart) {
        return
      }
      this.chart.dispose()
      this.chart = null
    },
    methods: {
      initChart() {
        let that = this
        this.chart = echarts.init(document.getElementById(this.id))
        const option = {
          tooltip: {
            trigger: 'item', // 或 show: false
          },
          grid: {
            left: '1%',
            right: '5%',
            top: '5%',
            bottom: '5%',
            containLabel: true,
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: [
                '00:00',
                '01:00',
                '02:00',
                '03:00',
                '04:00',
                '05:00',
                '06:00',
                '07:00',
                '08:00',
                '09:00',
                '10:00',
                '11:00',
                '12:00',
                '13:00',
                '14:00',
                '15:00',
                '16:00',
                '17:00',
                '18:00',
                '19:00',
                '20:00',
                '21:00',
                '22:00',
                '23:00',
                '00:00',
              ],

              axisLabel: {
                margin: this.id == 'VolumeChart1' ? 7 : 3,
                fontSize: this.id == 'VolumeChart1' ? 10 : 6,
                formatter: function (value) {
                  if (
                    value === '00:00' ||
                    value === '03:00' ||
                    value === '06:00' ||
                    value === '09:00' ||
                    value === '12:00' ||
                    value === '15:00' ||
                    value === '18:00' ||
                    value === '21:00'
                  ) {
                    return value
                  } else {
                    return '' // 保持原有的刻度文字不变
                  }
                },
              },
              axisTick: {
                show: false,
              },
              // axisTick: {
              //   alignWithLabel: true,
              //   interval: 2,
              // },
              splitLine: {
                show: true, // 启用网格线
                lineStyle: {
                  color: '#b7b8bc', // 设置网格线颜色
                },
                tooltip: {
                  show: false,
                },
              },
            },
          ],
          yAxis: [
            {
              name: '',
              type: 'value',
              min: 0,
              max: 20,
              // interval: 20,
              // 去掉网格线
              axisLine: { show: true },
              // 去掉Y轴刻度
              axisTick: { show: false },
              splitLine: {
                show: true, // 启用网格线
                lineStyle: {
                  color: '#000', // 网格线颜色
                },
                // 自定义筛选条件，仅显示值为20的网格线
                show: true,
              },
              splitNumber: 1,
              axisLabel: {
                margin: this.id == 'VolumeChart1' ? 5 : 3, // 调整与Y轴的距离，数值越小越贴近轴线
                fontSize: this.id == 'VolumeChart1' ? 10 : 6,
                formatter: function (value, index) {
                  var texts = []
                  if (value === 0) {
                    texts.push('')
                  }
                  if (value === 20) {
                    texts.push('20')
                  }
                  return texts
                },
              },
            },
          ],

          series: [
            // {
            //   name: '',
            //   type: 'line',
            //   smooth: true,
            //   stack: 'Total',
            //   itemStyle: {
            //     color: 'rgba(255, 255, 255, 0)',
            //   },
            //   emphasis: {
            //     focus: 'series',
            //   },
            //   data: [
            //     3.7, 3.8, 4.7, 4.1, 5.9, 4.7, 2.7, 6.6, 6.6, 5.6, 5.7, 4.7, 4.7, 3.7, 6.7, 6.7, 5.7, 5.7, 7.7, 7.7, 2.7,
            //     2.7, 3.7, 3.7,
            //   ],
            //   markLine: {
            //     symbol: 'none', // 去除箭头
            //     data: [
            //       {
            //         yAxis: 3.9, // 设置 Y 轴值
            //         name: 'Line at 3.9',
            //       },
            //       {
            //         yAxis: 10, // 设置 Y 轴值
            //         name: 'Line at 10',
            //       },
            //     ],
            //     lineStyle: {
            //       type: 'solid', // 线条类型为实线
            //       color: '#5acfff', // 横线颜色
            //       width: this.id == 'VolumeChart1' ? 1.5 : 1, // 横线宽度
            //     },
            //     label: {
            //       show: true, // 显示标签
            //       position: 'start', // 标签位置在左侧
            //       color: '#292929', // 标签文本颜色
            //       fontSize: this.id == 'VolumeChart1' ? 10 : 6, // 可根据需要调整字体大小
            //       distance: this.id == 'VolumeChart1' ? 4 : 2,
            //     },
            //   },
            // },
            {
              name: '10%',
              type: 'line',
              smooth: true,
              stack: 'Total',
              areaStyle: {
                color: '#fff',
              },
              lineStyle: {
                width: 0, // 隐藏线条
              },
              symbol: 'none', // 隐藏圆点
              emphasis: {
                focus: 'series',
              },
              data: that.distributionData._10_0,
              markLine: {
                symbol: 'none', // 去除箭头
                data: [
                  {
                    yAxis: 3.9, // 设置 Y 轴值
                    name: 'Line at 3.9',
                  },
                  {
                    yAxis: 10, // 设置 Y 轴值
                    name: 'Line at 10',
                  },
                ],
                lineStyle: {
                  type: 'solid', // 线条类型为实线
                  color: '#5acfff', // 横线颜色
                  width: this.id == 'VolumeChart1' ? 1.2 : 1, // 横线宽度
                },
                label: {
                  show: true, // 显示标签
                  position: 'start', // 标签位置在左侧
                  color: '#292929', // 标签文本颜色
                  fontSize: this.id == 'VolumeChart1' ? 10 : 6, // 可根据需要调整字体大小
                  distance: this.id == 'VolumeChart1' ? 4 : 2,
                },
              },
            },
            {
              name: '10%-25%',
              type: 'line',
              smooth: true,
              stack: 'Total',
              areaStyle: {
                color: '#b3e4fb',
              },
              lineStyle: {
                width: 0, // 隐藏线条
              },
              symbol: 'none', // 隐藏圆点
              emphasis: {
                focus: 'series',
              },
              data: that.distributionData._25_10,
            },
            {
              name: '_50',
              type: 'line',
              stack: 'Total',
              smooth: true,
              itemStyle: {
                color: '#000',
              },
              lineStyle: {
                width: this.id == 'VolumeChart1' ? 1.8 : 1, // 隐藏线条
              },
              symbol: 'none', // 隐藏圆点
              areaStyle: {
                color: '#0db8fc',
              },
              emphasis: {
                focus: 'series',
              },
              data: that.distributionData._50_25,
            },
            {
              name: '75%',
              type: 'line',
              smooth: true,
              stack: 'Total',
              areaStyle: {
                color: '#0db8fc',
              },
              lineStyle: {
                width: 0, // 隐藏线条
              },
              symbol: 'none', // 隐藏圆点
              emphasis: {
                focus: 'series',
              },
              data: that.distributionData._75_50,
            },
            {
              name: '90%',
              type: 'line',
              smooth: true,
              stack: 'Total',
              areaStyle: {
                color: '#b3e4fb',
              },
              lineStyle: {
                width: 0, // 隐藏线条
              },
              symbol: 'none', // 隐藏圆点
              emphasis: {
                focus: 'series',
              },
              data: that.distributionData._90_75,
            },
          ],
        }

        this.chart.setOption(option)
      },
    },
  }
</script>

<style scoped lang="less">
  #VolumeChart1 {
    height: 1.4rem;
  }
  #VolumeChart2 {
    height: 0.75rem;
  }
  #VolumeChart3 {
    height: 0.75rem;
  }
</style>
