<template>
  <div
    class="common-box"
    :class="$store.state.isLandscape ? 'shuiping' : 'chuizhi'"
    :style="{ backgroundColor: $store.state.isLandscape ? '#f8f8f8' : '' }"
  >
    <!-- 图表 -->
    <van-loading v-if="picLoading" />
    <div id="container1" ref="container1" style="width: 100%" :style="{ height: chartHeight + 'rem' }" v-else></div>
    <!-- 时间切换 -->
    <div class="changeTime" :class="{ top0: isBig }">
      <span
        @click="changeTime(item, index)"
        v-for="(item, index) in btnList"
        :key="index"
        :class="{ active: index == currentIndex }"
      >
        {{ item.n }}小时
      </span>
    </div>
    <!-- 横屏图标 -->
    <div class="handleShow" :class="{ top0: isBig }" @click="$store.state.isLandscape ? outFull() : inFull()">
      <img
        :src="$store.state.isLandscape ? require('../../static/pic/nofull.png') : require('../../static/pic/full.png')"
      />
    </div>
  </div>
</template>

<script>
  import { debounce } from 'lodash'
  import { getSugerbyId, loopTreatments, getAllTempArry, getEvent } from '@/api/userServices'
  import { Mix, G2 } from '@antv/g2plot'
  import yds from '@/static/pic/loopYDS.png'
  import { loopHistory } from '@/api/relationship'
  import { mapState, mapActions, mapMutations } from 'vuex'
  const G = G2.getEngine('canvas')
  export default {
    props: {
      // btnNum: {
      //   default: '',
      // },
      isIndex: {
        default: '',
      },
      isAlarm: {
        default: '',
      },
    },
    data() {
      return {
        currentIndex: 0,
        btnList: [
          { n: 3, v: 0.875 },
          { n: 6, v: 0.75 },
          { n: 12, v: 0.5 },
          { n: 24, v: 0 },
        ],
        tickInterval: 30 * 60 * 1000,
        bottomNum: '',
        timer: null, // 5分钟一次的定时器
        timerNum: 0,
        mixPlot: null,
        chartData: [],
        userId: JSON.parse(localStorage.getItem('appuser')).id,
        chartTime: 0.875,
        trend: '',
        sliderEnd: 1,
        sliderStart: 0,
        isDown: false,
        startX: '',
        endX: '',
        moveX: '',
        boxWidth: '',
        moveRate: '',
        downLeft: '',
        downRight: '',
        isHaveSugar: true,
        targetHigh: '',
        targetLow: '',
        allTempArray: [], // 基础率组合数组
        loopRemote: JSON.parse(localStorage.getItem('appuser')).loopRemote,
        chartHeight: '',
        // 下面是socket相关参数
        socketId: JSON.parse(localStorage.getItem('appuser')).id,
        isBig: false,
        picLoading: true,
        maxTemp: 1.6,
      }
    },
    created() {
      this.targetHigh = JSON.parse(localStorage.getItem('appuser')).targetHigh || '10'
      this.targetLow = JSON.parse(localStorage.getItem('appuser')).targetLow || '3.9'
    },
    mounted() {
      this.minTime = this.minTime3
      this.timer = setInterval(() => {
        this.timerNum++
      }, 5 * 60 * 1000)

      this.getAllData()

      //定义回调函数
      this.$ws.type1 = debounce(this.getAllData, 2000)
      this.chartHeight = 2.5
    },
    beforeDestroy() {
      this.$ws.type1 = null
      clearInterval(this.timer) // 停止定时
    },
    methods: {
      changeTime(item, index) {
        this.bottomNum = item.v
        this.currentIndex = index
      },
      // 格式化时间
      formatTime(timestamp) {
        const date = new Date(timestamp)
        return this.$moment(timestamp).format('YYYY-MM-DD HH:mm:ss') // 返回 'YYYY-MM-DDTHH:mm:ss' 格式
      },
      // 获取当前时间时间戳
      parseTime(time) {
        return new Date(time).getTime()
      },
      // 数组去重
      removeDuplicates(arr, key) {
        const seen = new Set()
        return arr.filter(item => {
          const val = item[key]
          if (seen.has(val)) {
            return false
          } else {
            seen.add(val)
            return true
          }
        })
      },
      // 20秒内如果胰岛素相同，则去重
      deduplicateData(data) {
        // 1. 复制并按时间戳升序排序
        const sortedData = [...data].sort((a, b) => new Date(a.create_time) - new Date(b.create_time))

        // 2. 用于记录某 insulin 值上一次出现的时间戳
        const lastOccurrence = {}

        // 3. 用于存放结果
        const result = []

        for (let item of sortedData) {
          const { create_time, insulin } = item

          // 判断是否在 20 秒内重复
          if (lastOccurrence.hasOwnProperty(insulin) && new Date(create_time) - lastOccurrence[insulin] < 20 * 1000) {
            // 如果是 20 秒内出现相同 insulin，则跳过
            continue
          } else {
            // 否则保留该条数据并更新记录
            result.push(item)
            lastOccurrence[insulin] = new Date(create_time)
          }
        }
        return result
      },
      // websoket来信息调用
      getAllData() {
        this.picLoading = true
        if (this.mixPlot) {
          this.mixPlot.destroy() // 确保销毁旧实例
          this.mixPlot = null // 释放内存
        }
        this.getData()
          .then(() => {
            return this.getAllEvent() // 确保返回的是 Promise
          })
          .then(() => {
            this.picLoading = false
            this.$nextTick(() => {
              this.getChart()
            })
          })
      },
      // 血糖事件集合数组按照事件排序
      sortTime(obj) {
        obj.sort((a, b) => {
          let t1 = new Date(Date.parse(a.create_time.replace(/-/g, '/')))
          let t2 = new Date(Date.parse(b.create_time.replace(/-/g, '/')))
          return t1.getTime() - t2.getTime()
        })
        return obj
      },
      // 获取所有事件
      getAllEvent() {
        return new Promise(resolve => {
          this.eventArr = []
          // 获取基础率和临时基础率组合数组
          const tempParams = {
            uid: this.userId,
            end: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
            start: this.$moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm:ss'),
            type: this.loopRemote,
          }

          getAllTempArry(tempParams).then(res => {
            // 基础率组合数组
            this.allTempArray = res ? res.data.data : []

            this.maxTemp = Math.max(...this.allTempArray.map(item => item.value))

            // 获取 ns 事件
            const start3 = this.$moment(this.minTime3).format('YYYY-MM-DD HH:mm:ss')
            const start6 = this.$moment(this.minTime6).format('YYYY-MM-DD HH:mm:ss')
            const start12 = this.$moment(this.minTime12).format('YYYY-MM-DD HH:mm:ss')
            const start24 = this.$moment(this.minTime24).format('YYYY-MM-DD HH:mm:ss')
            const nsParams = {
              uid: this.userId,
              start:
                this.chartTime == 0.875
                  ? start3
                  : this.chartTime == 0.75
                  ? start6
                  : this.chartTime == 0.5
                  ? start12
                  : this.chartTime == 0
                  ? start24
                  : start3,
              end: this.$moment(this.maxTime).format('YYYY-MM-DD HH:mm:ss'),
              type: this.loopRemote,
            }
            loopTreatments(nsParams).then(({ data }) => {
              if (data.treatments) {
                data.treatments.forEach(item => {
                  if (item.eventType === 'Temp Basal') {
                  }
                  // 胰岛素
                  else if (item.eventType === 'Correction Bolus' || item.eventType === 'Meal Bolus') {
                    if (item.insulin) {
                      item.createTime = item.create_time
                      item.v = 0
                      item.type = 'Correction Bolus'
                      item.insulin = (item.insulin * 1).toFixed(2)
                      this.eventArr.push(item) // 添加ns事件---胰岛素
                    }
                  }
                  // 碳水分组
                  else if (item.eventType === 'Carb Correction') {
                    item.createTime = item.create_time
                    item.v = -1
                    item.type = item.eventType
                    this.eventArr.push(item) // 添加ns事件---碳水
                  }
                  // 覆盖方波分组
                  else if (
                    this.loopRemote == 'loop'
                      ? item.eventType === 'Temporary Override'
                      : item.eventType === 'Temporary Target'
                  ) {
                    item.createTime = item.create_time
                    item.v = -2
                    item.type = 'Temporary Override'
                    item.duration = item.durationInMilliseconds
                      ? (item.durationInMilliseconds / 1000 / 60).toFixed(1)
                      : (item.duration * 1).toFixed(1)
                    this.eventArr.push(item) // 添加ns事件---覆盖方波
                  }
                  // 泵分组
                  else if (
                    this.loopRemote == 'loop' ? item.eventType === 'Suspend Pump' : item.eventType === 'OpenAPS Offline'
                  ) {
                    item.createTime = item.create_time
                    item.v = -2
                    item.type = 'Suspend Pump'
                    this.eventArr.push(item)
                  }
                  // 其他
                  // else {
                  //   item.createTime = item.create_time;
                  //   item.v = -2;
                  //   item.type = item.eventType;
                  //   this.eventArr.push(item);
                  // }
                })
                this.eventArr = this.deduplicateData(this.eventArr)
              }
              this.eventArr.forEach(event => {
                event.create_time = event.createTime
                event.type = event.type
                event.tooltip_time = event.createTime.substring(11, 16)
                event.v = 1

                this.chartData.push(event)
              })

              this.sortTime(this.chartData)
              let v2 = ''
              let v1 = ''
              this.chartData.forEach((item, index) => {
                // 把时间、血糖合并数组里的血糖先挑出来处理
                if (item.type != 'sugar') {
                  if (item.type == 'Temporary Override') {
                    item.v = 18.5
                  } else {
                    for (let i = index - 1; i >= 0; i--) {
                      if (this.chartData[i].type == 'sugar') {
                        v1 = this.chartData[i].v
                        break
                      }
                    }

                    for (let i = index + 1; i <= this.chartData.length - 1; i++) {
                      if (this.chartData[i].type == 'sugar') {
                        v2 = this.chartData[i].v
                        break
                      }
                    }
                    if (v1 && v2) {
                      item.v = (v1 + v2) / 2
                    } else if (v1) {
                      item.v = v1
                    } else if (v2) {
                      item.v = v2
                    }
                  }
                }
                // 把每个点（包括事件，否则可能会图表错乱）都复值前一个基础率
                const time = this.$moment(item.create_time).valueOf()

                if (this.allTempArray.length) {
                  this.allTempArray.forEach((temp, i) => {
                    const preTime = this.$moment(temp.timestamp).valueOf()
                    const nextTime = this.allTempArray[i + 1]
                      ? this.$moment(this.allTempArray[i + 1].timestamp).valueOf()
                      : null
                    if ((preTime <= time && nextTime >= time) || (preTime <= time && !nextTime)) {
                      item.tempValue = temp.value
                      item.tempType = temp.type
                    }
                  })
                }
              })
              // 获取糖仁云事件
              const params = {
                id: this.userId,
                start:
                  this.chartTime == 0.875
                    ? start3
                    : this.chartTime == 0.75
                    ? start6
                    : this.chartTime == 0.5
                    ? start12
                    : this.chartTime == 0
                    ? start24
                    : start3,
                end: this.$moment(this.maxTime).format('YYYY-MM-DD HH:mm:ss'),
              }
              getEvent(params).then(({ data }) => {
                data.data.forEach(trEvent => {
                  trEvent.create_time = trEvent.createTime
                  trEvent.v = 21

                  this.chartData.push(trEvent)
                })
                resolve()
              })
            })
          })
        })
      },
      getChart() {
        const box = this.$refs.container1
        if (!box) return
        if (this.mixPlot) {
          this.mixPlot.destroy() // 确保销毁旧实例
          this.mixPlot = null // 释放内存
        }
        // const data = this.removeDuplicates(this.chartData, 'create_time')
        const data = this.chartData
        console.log(data, 'data')

        this.mixPlot = new Mix('container1', {
          appendPadding: 8,
          padding: [40, 10, 30, 20],
          interactions: [{ type: 'tooltip' }],
          syncViewPadding: true,
          data,
          supportCSSTransform: true,
          tooltip: {
            showTitle: false,
            crosshairs: {
              type: 'x',
              follow: true,
            },
            interactions: [{ type: 'tooltip' }],
            position: 'top',
            customContent: (title, data) => {
              let loopType = title ? (data[0].data.json ? JSON.parse(data[0].data.json).type : '') : ''
              let type = ''
              let eventType = title ? data[0].data.type : ''
              let res = title ? data[0].data : ''
              let parse = title ? (data[0].data.json ? JSON.parse(data[0].data.json) : '') : ''
              if (title && data[0].data.type === 'zx') {
                type = '指尖血'
                return `<div style="padding:2px;">
                        <div style="font-size:10px;font-weight: 700;margin-bottom:2px;">${type}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> ${title}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> 场景：${res.eventValue}</div>
                        <div style="margin-bottom:2px;font-size:10px;">指血指数：${res.way}mmol/L</div>
                      </div>`
              } else if (title && data[0].data.type === 'kfy') {
                type = '口服药'
                return `<div style="padding:2px;">
                        <div style="font-size:10px;font-weight: 700;margin-bottom:2px;">${type}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> ${title}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> 用药时段：${res.scene}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> 药名：${res.way}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> 用量：${res.eventValue}</div>
                      </div>`
              } else if (title && data[0].data.type === 'ts') {
                type = '碳水'
                return `<div style="padding:2px;">
                        <div style="font-size:10px;font-weight: 700;margin-bottom:2px;">${type}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> ${title}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> ${res.eventValue}</div>
                      </div>`
              } else if (title && data[0].data.type === 'yds') {
                type = '胰岛素'
                return `<div style="padding:2px;">
                        <div style="font-size:10px;font-weight: 700;margin-bottom:2px;">${type}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> ${title}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> 注射时段：${res.scene}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> 胰岛素名称：${res.eventValue}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> 注射剂量：${res.way}U</div>
                      </div>`
              } else if (title && data[0].data.type === 'yd') {
                type = '运动'
                return `<div style="padding:2px;">
                        <div style="font-size:10px;font-weight: 700;margin-bottom:2px;">${type}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> ${title}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> 运动时常：${res.scene}h</div>
                        <div style="margin-bottom:2px;font-size:10px;"> 消耗：${res.eventValue}kcal</div>
                        <div style="margin-bottom:2px;font-size:10px;"> 备注：${res.way}</div>
                      </div>`
              } else if (title && loopType == 1) {
                type = '关闭闭环'
              } else if (title && loopType == 2) {
                type = '开启闭环'
              } else if (title && loopType == 3) {
                type = '覆盖方波'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> 持续时间：${parse.minutes}</div>
                        <div style="margin-bottom:4px;"> 原因：${parse.fugainame}</div>
                      </div>`
              } else if (title && loopType == 4) {
                type = '取消方波'
              } else if (title && loopType == 5) {
                type = '添加碳水'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> 总量：${parse.carbs}g</div>
                        <div style="margin-bottom:4px;"> 吸收时间：${parse.carbsHouers}h</div>
                      </div>`
              } else if (title && loopType == 6) {
                type = '大剂量'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> 剂量：${parse.bolus}U</div>
                      </div>`
              } else if (title && loopType == 7) {
                type = '临时基础率'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> 临时基础率：${parse.perHour}U/h</div>
                        <div style="margin-bottom:4px;"> 持续时间：${parse.baseHouers}h</div>
                      </div>`
              } else if (title && loopType == 8) {
                type = '取消临时'
              } else if (title && eventType == 'Correction Bolus') {
                type = '胰岛素'
                return `<div style="padding:2px;">
                        <div style="font-size:10px;font-weight: 700;margin-bottom:2px;">${type}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> ${title}</div>
                        <div style="margin-bottom:2px;font-size:10px;"> 剂量：${(res.insulin * 1).toFixed(2)}U</div>
                        <div style="margin-bottom:2px;font-size:10px;"> 名称：${res.insulinType || '无'}</div>
                      </div>`
              } else if (title && eventType == 'Carb Correction') {
                type = '碳水'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> 克数：${res.carbs ? res.carbs + 'g' : '无'}</div>
                        <div style="margin-bottom:4px;"> 类型：${res.foodType ? res.foodType : '无'}</div>
                      </div>`
              } else if (title && eventType == 'Temporary Override') {
                type = '覆盖方波'
                return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div style="margin-bottom:4px;"> ${title}</div>
                        <div style="margin-bottom:4px;"> 需求比例：${
                          res.insulinNeedsScaleFactor ? res.insulinNeedsScaleFactor : '无'
                        }</div>
                        <div style="margin-bottom:4px;"> 持续时间：${res.duration}分钟</div>
                        <div style="margin-bottom:4px;"> 原因：${res.reason}</div>
                      </div>`
              } else if (title && eventType == 'Suspend Pump') {
                type = '泵'
              } else if (title && eventType == 'sugar') {
                type = '血糖'
                return `<div style="padding:2px;">
                        <div style="font-size:10px;font-weight: 700;margin-bottom:2px;">${type}:${data[0].data.v}</div>
                        <div style="margin-bottom:2px;font-size:10px"> 基础率:${
                          data[0].data.tempValue
                            ? data[0].data.tempValue.toFixed(2)
                            : data[0].data.tempValue == 0
                            ? 0
                            : '无'
                        }</div>
                        <div style="margin-bottom:2px;font-size:10px"> ${title}</div>

                      </div>`
              } else {
                type = '其他'
              }
              return `<div style="padding:4px;">
                        <div style="font-size:14px;font-weight: 700;margin-bottom:4px;">${type}</div>
                        <div> ${title}</div>
                      </div>`
            },
          },
          plots: [
            {
              type: 'scatter',
              top: true,
              options: {
                xField: 'create_time',
                yField: 'v',
                // size: this.chartTime == 0.875 ? 3 : this.chartTime == 0.75 || this.chartTime == 0.5 ? 2 : 1,
                shape: 'circle',
                pointStyle: {
                  stroke: null, // 移除边框
                  fillOpacity: 1, // 填充不透明
                },
                sizeField: 'type',
                size: ({ type }) => {
                  if (type != 'sugar') {
                    return 0
                  } else {
                    return this.chartTime == 0.875 ? 3 : this.chartTime == 0.75 ? 2 : this.chartTime == 0.5 ? 1.5 : 1
                  }
                },
                colorField: 'v',

                color: ({ v }) => {
                  return v > this.targetHigh
                    ? '#F4664A'
                    : v > this.targetLow
                    ? '#009944'
                    : v > 0
                    ? '#ffaa29'
                    : '#f8f8f8' // y 值大于 25 时颜色为红色，否则为蓝色
                },
                // 隐藏颜色控件图例
                legend: {
                  visible: false,
                },
                supportCSSTransform: true,
                yAxis: {
                  line: null,
                  tickMethod: () => [0, 5, 10, 15, 20, 25],
                },
                xAxis: {
                  type: 'time',
                  max: this.maxTime,
                  min: this.minTime,
                  tickInterval: this.tickInterval,
                  mask: 'HH:mm', // 显示格式为小时:分钟
                  // range: [0, 1], // 刻度范围为0到1
                  tickMethod: scale => {
                    const { min, max, tickCount } = scale
                    // const avg = (max - min) / tickCount;
                    const ticks = []
                    for (let i = min; i <= max; i += this.tickInterval) {
                      ticks.push(i)
                    }
                    return ticks
                  },
                },
                label: {
                  offsetY: 0,
                  content: item => {
                    this.loopType = item.json ? JSON.parse(item.json).type : ''
                    if (item.type == 'kfy') {
                      const group = new G.Group({})
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x: 0,
                          y: 12,
                          width: 10,
                          height: 10,
                          img: require('@/static/pic/yaowu.png'),
                        },
                      })
                      // group.addShape({
                      //   type: 'text',
                      //   attrs: {
                      //     x: 6,
                      //     y: 2,
                      //     text: item.type,
                      //     textAlign: 'center',
                      //     textBaseline: 'top',
                      //     fill: '#000'
                      //   }
                      // })
                      return group
                    } else if (item.type == 'ts') {
                      const group = new G.Group({})
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x: 0,
                          y: 12,
                          width: 10,
                          height: 10,
                          img: require('@/static/pic/tanshui.png'),
                        },
                      })
                      return group
                    } else if (item.type == 'yds') {
                      const group = new G.Group({})
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x: 0,
                          y: 12,
                          width: 10,
                          height: 10,
                          img: require('@/static/pic/yidaosu.png'),
                        },
                      })
                      return group
                    } else if (item.type == 'yd') {
                      const group = new G.Group({})
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x: 0,
                          y: 12,
                          width: 10,
                          height: 10,
                          img: require('@/static/pic/yundong.png'),
                        },
                      })
                      return group
                    } else if (item.type == 'zx') {
                      const group = new G.Group({})
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x: 0,
                          y: 12,
                          width: 10,
                          height: 10,
                          img: require('@/static/pic/xuetang.png'),
                        },
                      })
                      return group
                    } else if (item.type == 'Correction Bolus') {
                      const group = new G.Group({})
                      let width = ''
                      let height = ''
                      let fontSize = ''
                      if (item.insulin < 1) {
                        width = 4
                        height = 4
                        fontSize = 5
                      } else if (item.insulin >= 1 && item.insulin <= 8) {
                        width = 4 + 1.4 * item.insulin
                        height = 4 + 1.4 * item.insulin
                        fontSize = 5 + 0.6 * item.insulin
                      } else if (item.insulin > 8) {
                        width = 4 + 1.4 * 8 + (item.insulin - 8) * 0.6
                        height = 4 + 1.4 * 8 + (item.insulin - 8) * 0.6
                        fontSize = 5 + 0.6 * 8 + item.insulin * 0.1
                      }
                      if (this.chartTime == 0.875) {
                        width = width * 1
                        height = height * 1
                        fontSize = fontSize * 1
                      } else if (this.chartTime == 0.75) {
                        width = width * 0.95
                        height = height * 0.95
                        fontSize = fontSize * 0.95
                      } else if (this.chartTime == 0.5) {
                        width = width * 0.9
                        height = height * 0.9
                        fontSize = fontSize * 0.9
                      } else if (this.chartTime == 0) {
                        width = width * 0.86
                        height = height * 0.86
                        fontSize = fontSize * 0.86
                      }
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x: 2,
                          y: 16,
                          // width: 1 * item.insulin < 1 ? 4 : 4 + 1.5 * item.insulin,
                          // height: 1 * item.insulin < 1 ? 4 : 4 + 1.5 * item.insulin,
                          width: width,
                          height: height,
                          img: yds,
                        },
                      })
                      group.addShape({
                        type: 'text',
                        attrs: {
                          x: 6,
                          y: 1 * item.insulin < 1 ? 18 : 1 * item.insulin < 6 ? 8 : 6,
                          // fontSize: 1 * item.insulin < 1 ? 5 : 5 + 0.8 * item.insulin,
                          fontSize: fontSize,
                          text: (item.insulin * 1).toFixed(1) + 'U',
                          textAlign: 'center',
                          textBaseline: 'top',
                          fill: 1 * item.insulin > 1 ? '#000' : '#000',
                        },
                      })
                      return group
                    } else if (item.type == 'Carb Correction') {
                      const group = new G.Group({})
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x: 7,
                          y: 21,
                          width: this.chartTime == 0 ? 6 : this.chartTime == 0.5 ? 7 : 8,
                          height: this.chartTime == 0 ? 6 : this.chartTime == 0.5 ? 7 : 8,
                          img: require('@/static/pic/tstr.png'),
                        },
                      })
                      group.addShape({
                        type: 'text',
                        attrs: {
                          x: 12,
                          y: 25,
                          fontSize: 6,
                          text: item.carbs + 'g',
                          textAlign: 'center',
                          textBaseline: 'top',
                          fill: '#424242',
                        },
                      })
                      return group
                    } else if (item.type == 'Temporary Override') {
                      const group = new G.Group({})
                      group.addShape({
                        type: 'image',
                        attrs: {
                          x:
                            this.chartTime == 0.875
                              ? item.duration * 1
                              : this.chartTime == 0.75
                              ? item.duration * 0.6
                              : this.chartTime == 0.5
                              ? item.duration * 0.4
                              : item.duration * 0.28,
                          y: 12,
                          width:
                            this.chartTime == 0.875
                              ? item.duration * 2.4
                              : this.chartTime == 0.75
                              ? item.duration * 1.2
                              : this.chartTime == 0.5
                              ? item.duration * 0.8
                              : item.duration * 0.5,
                          height: 12,
                          img: require('@/static/pic/override.png'),
                        },
                      })
                      group.addShape({
                        type: 'text',
                        attrs: {
                          x:
                            this.chartTime == 0.875
                              ? item.duration * 1.3
                              : this.chartTime == 0.75
                              ? item.duration * 0.9
                              : this.chartTime == 0.5
                              ? item.duration * 0.65
                              : item.duration * 0.5,
                          y: 12,
                          fontSize: 8,
                          text: item.reason,
                          textAlign: 'center',
                          textBaseline: 'top',
                          fill: '#000',
                        },
                      })
                      return group
                    }
                    // else if (item.type == 'Suspend Pump') {
                    //   const group = new G.Group({})
                    //   group.addShape({
                    //     type: 'image',
                    //     attrs: {
                    //       x: 0,
                    //       y: 10,
                    //       width: this.chartTime == 0 ? 9 : this.chartTime == 0.5 ? 11 : 13,
                    //       height: this.chartTime == 0 ? 9 : this.chartTime == 0.5 ? 11 : 13,
                    //       img: require('@/static/pic/pump.png'),
                    //     },
                    //   })
                    //   return group
                    // }
                  },
                  labelLine: true,
                },
                annotations: [
                  {
                    type: 'region',
                    start: ['min', this.targetLow],
                    end: ['max', this.targetHigh],
                    // start: ['min', 3.9],
                    // end: ['max', 10],
                    style: {
                      stroke: '#edf0f2',
                      lineDash: [2, 2],
                    },
                  },

                  {
                    type: 'text',
                    // position: ['min', 10],
                    position: ['min', this.targetHigh],
                    content: this.targetHigh,
                    // content: 10,
                    style: {
                      fill: '#F4664A',
                    },
                  },

                  {
                    type: 'text',
                    position: ['min', this.targetLow],
                    content: this.targetLow,
                    // content: 3.9,
                    // position: ['min', 3.9],
                    style: {
                      fill: '#ffaa29',
                    },
                  },
                  // {
                  //   type: 'regionFilter',
                  //   start: ['min', 0],
                  //   end: ['max', 3.9],
                  //   color: '#ffaa29',
                  // },
                  // {
                  //   type: 'regionFilter',
                  //   start: ['min', 10],
                  //   end: ['max', 20],
                  //   color: '#F4664A',
                  // },
                ],
              },
            },
            {
              type: 'line',
              top: true,
              options: {
                xField: 'create_time',
                yField: 'tempValue',
                stepType: 'vh',
                connectNulls: true,
                color: '#009944',
                lineStyle: {
                  lineWidth: 1,
                },
                // area: {
                //   smooth: true, // 设置曲线平滑
                //   style: {
                //     fill: 'l(270) 0:#ffffff 1:#1890ff', // 线条下方区域渐变色填充
                //     fillOpacity: 0.3, // 控制透明度
                //   },
                // },
                xAxis: {
                  // visible: false,
                  type: 'time',
                  max: this.maxTime,
                  min: this.minTime,
                  tickInterval: this.tickInterval,
                  mask: 'HH:mm', // 显示格式为小时:分钟
                  // range: [0, 1], // 刻度范围为0到1
                  tickMethod: scale => {
                    const { min, max, tickCount } = scale
                    // const avg = (max - min) / tickCount;
                    const ticks = []
                    for (let i = min; i <= max; i += this.tickInterval) {
                      const arr = [5, 10, 15, 22]
                      ticks.push(i)
                    }
                    return ticks
                  },
                },
                yAxis: {
                  min: 0,
                  max: this.maxTemp * 5.6,
                  label: null,
                  grid: null,
                },
              },
            },
          ],
          xAxis: {
            // visible: false,
            type: 'time',
            max: this.maxTime,
            min: this.minTime,
            tickInterval: this.tickInterval,
            mask: 'HH:mm', // 显示格式为小时:分钟
            // range: [0, 1], // 刻度范围为0到1
            tickMethod: scale => {
              const { min, max, tickCount } = scale
              // const avg = (max - min) / tickCount;
              const ticks = []
              for (let i = min; i <= max; i += this.tickInterval) {
                ticks.push(i)
              }
              return ticks
            },
          },
        })
        this.mixPlot.render()

        this.mixPlot.on('slider:touchmove', (...args) => {
          this.handleMove(args[0])
        })

        this.mixPlot.on('plot:touchmove', event => {
          const { x } = event
          // 获取点图中的所有数据点
          const elements = this.mixPlot.chart.geometries[0].elements
          let closestElement = null
          let minDistance = Infinity

          elements.forEach(element => {
            const bbox = element.shape.getCanvasBBox()
            const elementX = (bbox.minX + bbox.maxX) / 2
            const distance = Math.abs(x - elementX)

            if (distance < minDistance) {
              minDistance = distance
              closestElement = element
            }
          })

          if (closestElement) {
            this.mixPlot.chart.showTooltip({
              x: (closestElement.shape.getCanvasBBox().minX + closestElement.shape.getCanvasBBox().maxX) / 2,
              y: (closestElement.shape.getCanvasBBox().minY + closestElement.shape.getCanvasBBox().maxY) / 2,
            })
          } else {
            this.mixPlot.chart.hideTooltip()
          }
        })

        // 确保触摸移出图表时隐藏 tooltip
        this.mixPlot.on('plot:touchend', () => {
          this.mixPlot.chart.hideTooltip()
        })
      },

      getData() {
        return new Promise(resolve => {
          this.chartData = []
          const params = {
            id: this.userId,
            end: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
            start: this.$moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm:ss'),
          }

          getSugerbyId(params).then(res => {
            if (res.data.data && res.data.data.length) {
              res.data.data.forEach(item => {
                this.chartData.push({
                  create_time: item.create_time,
                  v: item.v,
                  tooltip_time: item.create_time.substring(11, 16),
                  type: 'sugar',
                })
              })
              const count = res.data.data.filter(item => item.v <= 10 && item.v > 3.9).length
              this.percentage = (count / res.data.data.length) * 100
              this.percentage = this.percentage.toFixed(0) * 1
              this.$emit('getPercent', this.percentage)

              const emitArr = this.removeDuplicates(res.data.data, 'create_time')
              const length = emitArr.length

              if (length > 1) {
                const leftV = emitArr[length - 1].v - emitArr[length - 2].v
                if (emitArr[length - 1].v > emitArr[length - 2].v) {
                  this.trend = leftV > 0.5 ? 3 : 1 // 3是极速上升  1是缓步上升
                } else if (emitArr[length - 1].v < emitArr[length - 2].v) {
                  this.trend = leftV > 0.5 ? 4 : 2 // 4是极速下降  2是缓步下降
                } else {
                  this.trend = 0
                }
                // const leftSugar = (emitArr[length - 1].v - emitArr[length - 2].v).toFixed(1)
                const lastTime = emitArr[length - 1].create_time
                this.$emit('getTrend', this.trend, lastTime)
              }
            } else {
              this.isHaveSugar = false
            }
            resolve()
          })
        })
      },
      // 退出全屏
      outFull() {
        this.isBig = false
        this.$store.commit('changeLand')
        // this.$emit('getIsFull', false)
        // document.querySelector('#container1').style.height = 300 + 'px'
        // document.querySelector('#temp').style.bottom = '55px'
        // document.querySelector('#temp').style.height = '55px'
        // this.chartHeight = this.$store.state.isLandscape ? window.innerWidth : 300
        this.chartHeight = 2.5
      },
      // 进入全屏
      inFull() {
        this.isBig = true
        this.$store.commit('changeLand')
        // this.isFullScreen = true
        // this.$emit('getIsFull', true)
        // const nowWidth = window.screen.width
        // document.querySelector('#container1').style.height = nowWidth - 100 + 'px'
        this.chartHeight = 3.3
      },
    },

    computed: {
      maxTime() {
        const nextTime = this.$moment()
          .add(1 + this.timerNum - this.timerNum, 'hours')
          .format('YYYY-MM-DD HH:00:00')
        return new Date(nextTime).getTime()
      },
      minTime3() {
        const min = this.$moment()
          .subtract(2 + this.timerNum - this.timerNum, 'hours')
          .format('YYYY-MM-DD HH:00:00')
        return new Date(min).getTime()
      },
      minTime6() {
        const min = this.$moment()
          .subtract(5 + this.timerNum - this.timerNum, 'hours')
          .format('YYYY-MM-DD HH:00:00')
        return new Date(min).getTime()
      },
      minTime12() {
        const min = this.$moment()
          .subtract(11 + this.timerNum - this.timerNum, 'hours')
          .format('YYYY-MM-DD HH:00:00')
        return new Date(min).getTime()
      },
      minTime24() {
        const min = this.$moment()
          .subtract(23 + this.timerNum - this.timerNum, 'hours')
          .format('YYYY-MM-DD HH:00:00')
        return new Date(min).getTime()
      },
    },

    watch: {
      // 每5分钟自动渲染一次chart2，要先清除
      timerNum: {
        handler: function (newVal, oldVal) {
          this.$nextTick(() => {
            this.getAllData()
          })
        },
      },

      // btnNum: {
      //   handler: function (newVal, oldVal) {
      //     this.chartTime = newVal
      //     this.sliderStart = this.chartTime
      //     this.sliderEnd = 1
      //     if (this.chartTime == 0.875) {
      //       this.minTime = this.minTime3
      //       this.tickInterval = 30 * 60 * 1000
      //     } else if (this.chartTime == 0.75) {
      //       this.minTime = this.minTime6
      //       this.tickInterval = 60 * 60 * 1000
      //     } else if (this.chartTime == 0.5) {
      //       this.minTime = this.minTime12
      //       this.tickInterval = 2 * 60 * 60 * 1000
      //     } else if (this.chartTime == 0) {
      //       this.minTime = this.minTime24
      //       this.tickInterval = 4 * 60 * 60 * 1000
      //     }
      //     const params = {
      //       id: this.userId,
      //       end: this.$moment().format('YYYY-MM-DD HH:mm:ss'),
      //       start: this.$moment().subtract(24, 'hours').format('YYYY-MM-DD HH:mm:ss'),
      //     }
      //     this.$nextTick(() => {
      //       this.getAllData()
      //     })
      //   },
      // },
      bottomNum: {
        handler: function (newVal, oldVal) {
          this.chartTime = newVal
          this.sliderStart = this.chartTime
          this.sliderEnd = 1

          if (this.chartTime == 0.875) {
            this.minTime = this.minTime3
            this.tickInterval = 30 * 60 * 1000
          } else if (this.chartTime == 0.75) {
            this.minTime = this.minTime6
            this.tickInterval = 60 * 60 * 1000
          } else if (this.chartTime == 0.5) {
            this.minTime = this.minTime12
            this.tickInterval = 2 * 60 * 60 * 1000
          } else if (this.chartTime == 0) {
            this.minTime = this.minTime24
            this.tickInterval = 4 * 60 * 60 * 1000
          }
          this.$nextTick(() => {
            this.getAllData()
          })
        },
      },
    },
  }
</script>

<style scoped lang="less">
  .common-box {
    width: 100%;
    border-radius: 1.2rem;
    background: rgb(255, 255, 255);
    // box-shadow: 2px 2px 2px 0px rgba(6, 14, 33, 0.16);
    position: relative;
  }
  .handleShow {
    position: absolute;
    right: 0.2rem;
    top: -0.38rem;
    width: 0.24rem;
    height: 0.24rem;
    z-index: 1;
  }
  .handleShow img {
    width: 0.25rem;
    height: 0.25rem;
  }
  .shuiping {
    width: 100vh;
    height: 100vw;
    border-radius: 0;
    /* box-shadow: 0; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    z-index: 9999;
    /* 阻止屏幕拖动 */
    overflow: hidden;
    touch-action: none;
  }
  .changeTime {
    display: flex;
    justify-content: space-around;
    position: absolute;
    top: -0.4rem;
    left: 0;
    width: 86%;
    z-index: 2;
    span {
      width: 0.4rem;
      height: 0.28rem;
      line-height: 0.28rem;
      font-size: 0.13rem;
      white-space: nowrap;
      text-align: center;
    }
  }
  .active {
    border-bottom: 2px solid #42a968;
  }
  .top0 {
    top: 0.15rem;
  }
  .van-loading {
    height: 2.5rem;
    .van-loading__spinner {
      margin-top: 1.2rem;
    }
  }
</style>
