<template>
  <div id="reportCenter" v-loading="loading">
    <div class="navTitle">统计</div>

    <!-- 日期选择 -->
    <div class="dateChose">
      <van-nav-bar
        title="标题"
        left-arrow
        @click-left="onClickLeft()"
        @click-right="nowDate !== selectedDate ? onClickRight() : null"
        class="vanBar"
      >
        <template #left>
          <van-icon name="arrow-left" />
        </template>
        <template #right>
          <van-icon name="arrow" :class="{ iActive: nowDate == selectedDate }" />
        </template>
        <template #title>
          <div
            @click="showDialog(showStart)"
            style="background: #7accff; padding: 2vw; border-radius: 0.2rem; color: #fff"
          >
            <span>{{ nowDate == selectedDate ? '今天' : selectedDate }}</span>

            <!-- <span v-if="currentIndex == -1">{{ nowDate == selectedDate ? '今天' : selectedDate }}</span>
            <span v-else-if="currentIndex == 0 || currentIndex == 1 || currentIndex == 2 || currentIndex == 3">
              {{ startDays + '~' + endDays }}
            </span> -->
            <van-icon name="notes-o" color="#fff" />
          </div>
        </template>
      </van-nav-bar>

      <van-popup v-model="showStart" position="bottom">
        <div class="popup-header">
          <span @click="showStart = false" style="font-size: 0.14rem">取消</span>
          <span @click="confirmChoose" style="font-size: 0.14rem; color: #1989fa">确认</span>
        </div>
        <van-datetime-picker
          v-model="defaultDate"
          :min-date="minDate"
          :max-date="maxDate"
          type="date"
          :show-toolbar="false"
        />
      </van-popup>
    </div>
    <!-- 顶部显示数据 -->
    <div class="topData flex">
      <div class="data-l flex">
        <div class="flex-c">
          <span>监测次数</span>
          <i>{{ sugarNum }}</i>
        </div>
        <div class="flex-c">
          <span>覆盖时间</span>
          <i>{{ sugarRate > 100 ? 100 : sugarRate }} %</i>
        </div>
        <div class="flex-c">
          <span>目标血糖</span>
          <i>3.9~10.0mmol/L</i>
        </div>
      </div>
      <div class="export" @click="$router.push('exReport')">查看报告</div>
    </div>

    <div :class="SugerData.length == 0 ? 'none' : 'show'" id="html2canvas" ref="html2canvas">
      <!-- 每日血糖曲线 -->

      <div class="daySugar">
        <div class="sugar-top flex">
          <img src="@/static/pic/daySugar.png" alt="" />
          <span>每日血糖</span>
        </div>
        <Report ref="nsPages" :selectedDate="selectedDate" :picLoading="picLoading" @changLoading="changLoading" />
      </div>

      <!-- 平均血糖 -->
      <div class="averSugar">
        <el-row>
          <el-col :span="12">
            <div class="sugarWord">平均血糖</div>
            <div class="sugar1">
              {{ averageSuger == 'NaN' ? '-' : averageSuger }}
              <span style="color: #7a7a7a; font-size: 0.09rem">mmol/L</span>
            </div>
            <div class="sugarWord" style="margin-bottom: 0rem">预估糖化血红蛋白</div>
            <div class="sugar2">
              {{ estimatedSaccharification == 'NaN' ? '-' : estimatedSaccharification }}
              <span style="color: #7a7a7a; font-size: 0.09rem">%</span>
            </div>
          </el-col>
          <el-col :span="12">
            <BarChart :SugerData="SugerData" :title="'bar1'" />
          </el-col>
        </el-row>
      </div>

      <!-- 统计参数 -->
      <div class="threeData">
        <!-- SD -->
        <div class="flex-c" style="padding-left: 0.14rem">
          <div class="tTop">SD标准偏差</div>
          <div class="tMid">
            {{ standardError == 'NaN' ? '-' : standardError }}
            <span>mmol/L</span>
          </div>
          <div class="tBot">&lt; 2mmol/L</div>
        </div>
        <!-- CV -->
        <div class="flex-c">
          <div class="tTop">CV变异系数</div>
          <div class="tMid">
            {{ CV == 'NaN' ? '-' : CV }}
            <span>%</span>
          </div>
          <div class="tBot">> 33%</div>
        </div>
        <!-- GVI -->
        <div class="flex-c">
          <div class="tTop">GVI变异指数</div>
          <div class="tMid">
            {{ GVI == 'NaN' ? '-' : GVI }}
          </div>
          <div class="tBot">&lt; 1.5</div>
        </div>
        <!-- PGS -->
        <div class="flex-c">
          <div class="tTop">PGS血糖状态</div>
          <div class="tMid">
            {{ PGS == 'NaN' ? '-' : PGS }}
          </div>
          <div class="tBot">&lt; 20</div>
        </div>
      </div>
      <!-- 每周血糖 -->
      <weeklySugar :isShow="true" />
      <!-- 8点血糖值 -->
      <HotChart />

      <div style="width: 100%; height: 0.24rem"></div>
    </div>

    <div v-if="SugerData.length == 0 && !loading" style="margin-top: 30%">
      <img src="@/static/pic/nodata.png" style="width: 2rem" alt="" />
      <div style="font-size: 0.16rem; font-weight: 330; margin-top: 0.18rem; color: #848894">暂无数据</div>
    </div>
  </div>
</template>
<script>
  import VolumeChart from '@/pages/reportCenter/component/volumeChart.vue'
  import HotChart from '@/pages/hotChart/hotChart.vue'
  import BarChart from '@/pages/reportCenter/component/barChart.vue'
  import Report from '@/pages/reportCenter/report.vue'
  import html2canvas from 'html2canvas'
  import todaySugar from './component/todaySugar.vue'
  import weeklySugar from './component/weeklySugar.vue'
  import { getBGbyToken } from '../../api/userServices'
  import {
    getaverage,
    getprotein,
    getstandard,
    getRate,
    getCV,
    getGVI,
    getPGS,
    formatData,
    getAllNum,
    getAllMid,
    getLow,
    getNormal,
    getHigh,
    getSevenData,
    getLowAvg,
    getNormalAvg,
    getHighAvg,
    getLowMid,
    getNormalMid,
    getHighMid,
    getLowStandard,
    getNormalStandard,
    getHighStandard,
    get24HourData,
    getLineChartData,
    getMaxRange,
  } from '../sugerData/getSugerData'
  export default {
    name: 'ReportCenter',
    components: { todaySugar, weeklySugar, Report, BarChart, HotChart, VolumeChart },
    data() {
      return {
        startTime: new Date(),
        endTime: new Date(),
        show: false,
        currentIndex: 0,
        loading: false,
        // 每日血糖概览
        dayAverageSuger: '-',
        // 最大血糖波动幅度
        dayMaxRange: '-',
        // 高血糖数
        dayMaxSugar: '-',
        // 低血糖数
        dayMinSugar: '-',
        selectedDate: '',
        nowDate: '',
        defaultDate: new Date(),
        // 自定义时间最大最小值
        minDate: new Date(2020, 0, 1),
        maxDate: new Date(),
        showStart: false,
        daysArr: [],
        weeksArr: [],
        bg14: [],
        // 血糖达标范围
        scopeOfBloodSugar: {
          high: 10,
          low: 3.9,
          extremelyHigh: 12,
          extremelyLow: 2,
        },
        test: '',
        SugerData: [],
        rateChart: false,
        startDate: this.$moment().format('YYYY-MM-DD 00:00:00'),
        endDate: this.$moment().format('YYYY-MM-DD 23:59:59'),
        startDays: this.$moment().subtract('6', 'days').format('YYYY/MM/DD'),
        endDays: this.$moment(this.selectedDate).format('YYYY/MM/DD'),
        chooseDay: '',
        averageSuger: '',
        estimatedSaccharification: '',
        standardError: '',

        CV: '',
        GVI: '',
        PGS: '',
        lineList: {
          x: [12.5, 25, 37.5, 50, 62.5, 75, 87.5],
          y: [74, 33.3],
        },
        pieChartData: [
          { value: 0, itemStyle: { color: '#ff1010' } },
          { value: 0, itemStyle: { color: '#65d172' } },
          { value: 0, itemStyle: { color: '#ffd426' } },
        ],
        statisticalParameters: [
          ['已读取', '10%', '80%', '10%', '优秀'],
          ['平均值', '10%', '80%', '10%', '优秀'],
          ['中位数', '10%', '80%', '10%', '优秀'],
          ['标准差', '10%', '80%', '10%', '优秀'],
        ],
        classList: ['bloodRate1', 'bloodRate2', 'bloodRate3', 'bloodRate4', 'bloodRate5'],
        // 每周对比图数据
        thisWeeks: [],
        lastWeeks: [],
        // 判断是否是患者报告页
        isPatient: '',
        structId: '',
        perenctDiff: '',
        lastWeekStart: this.$moment().subtract('13', 'days').format('YYYY/MM/DD'),
        lastWeekStartDate: this.$moment().subtract('13', 'days').format('YYYY-MM-DD 00:00:00'),
        lastWeekEnd: this.$moment().subtract('7', 'days').format('YYYY/MM/DD'),
        lastWeekEndDate: this.$moment().subtract('7', 'days').format('YYYY-MM-DD 23:59:59'),
        thisWeekStart: this.$moment().subtract('6', 'days').format('YYYY/MM/DD'),
        thisWeekStartDate: this.$moment().subtract('6', 'days').format('YYYY-MM-DD 00:00:00'),
        thisWeekEnd: this.$moment().subtract('0', 'days').format('YYYY/MM/DD'),
        thisWeekEndDate: this.$moment().subtract('0', 'days').format('YYYY-MM-DD 23:59:59'),
        perenctDiff2: '',
        perenctDiff3: '',
        averageSuger2: '',
        averageSuger3: '',
        estimatedSaccharification2: '',
        estimatedSaccharification3: '',
        SugerData2: [],
        SugerData3: [],
        sugarNum: 0,
        sugarRate: 0,
        picLoading: true,
      }
    },
    watch: {
      selectedDate: {
        handler: function (newVal, oldVal) {
          this.getDaySugar(newVal)
        },
      },
    },
    created() {
      this.loading = true
      this.isPatient = this.$route.query.id ? true : false
      this.structId = this.$route.query.struct_id ? this.$route.query.struct_id : ''
      this.nowDate = this.$moment().format('YYYY-MM-DD')
      this.selectedDate = this.$moment().format('YYYY-MM-DD')
      setTimeout(() => {
        this.getBG14()
        // this.lastWeekBG()
        // this.thisWeekBG()
      }, 300)
    },
    mounted() {
      this.initialization()
      // 分享报告
      window.saveImageToBlob = this.saveImageToBlob

      // 监听滚动事件
      const navbar = document.querySelector('.navTitle')
      window.addEventListener('scroll', () => {
        if (window.scrollY > 0) {
          // 页面滚动时，添加 box-shadow
          navbar.classList.add('with-shadow')
        } else {
          // 页面没有滚动时，移除 box-shadow
          navbar.classList.remove('with-shadow')
        }
      })
    },

    methods: {
      changLoading() {
        this.picLoading = false
      },
      // confirmRange() {
      //   this.startDate = this.$moment(this.startTime).format('YYYY-MM-DD 00:00:00')
      //   this.endDate = this.$moment(this.endTime).format('YYYY-MM-DD 23:59:59')
      //   const small = this.$moment(this.startTime).valueOf()
      //   const big = this.$moment(this.endTime).valueOf()
      //   this.startDays = this.$moment(this.startTime).format('YYYY-MM-DD')
      //   this.endDays = this.$moment(this.endDate).format('YYYY-MM-DD')
      //   if (small > big) {
      //     this.$toast('开始时间不能大于结束时间')
      //   } else {
      //     this.currentIndex = 3
      //     setTimeout(() => {
      //       this.getBG14()
      //       this.lastWeekBG()
      //       this.thisWeekBG()
      //     }, 300)
      //     this.show = false
      //   }
      // },
      // 选择不同的天数看柱状图
      // changDays(num) {
      //   if (num == 1) {
      //     this.currentIndex = -1
      //     this.startDate = this.$moment().subtract('0', 'days').format('YYYY-MM-DD 00:00:00')
      //     this.startDays = this.$moment().subtract('0', 'days').format('YYYY/MM/DD')
      //   } else if (num == 7) {
      //     this.currentIndex = 0
      //     this.startDate = this.$moment().subtract('6', 'days').format('YYYY-MM-DD 00:00:00')
      //     this.startDays = this.$moment().subtract('6', 'days').format('YYYY/MM/DD')
      //   } else if (num == 14) {
      //     this.currentIndex = 1
      //     this.startDate = this.$moment().subtract('13', 'days').format('YYYY-MM-DD 00:00:00')
      //     this.startDays = this.$moment().subtract('13', 'days').format('YYYY/MM/DD')
      //   } else if (num == 30) {
      //     this.currentIndex = 2
      //     this.startDate = this.$moment().subtract('29', 'days').format('YYYY-MM-DD 00:00:00')
      //     this.startDays = this.$moment().subtract('29', 'days').format('YYYY/MM/DD')
      //   }
      //   this.endDays = this.$moment(this.selectedDate).format('YYYY/MM/DD')
      //   setTimeout(() => {
      //     this.getBG14()
      //     // this.lastWeekBG()
      //     // this.thisWeekBG()
      //   }, 300)
      // },
      getDays() {
        this.show = true
      },
      // 返回我的患者列表
      toMyPatientList() {
        this.$router.push({
          path: '/MineView/PersonnelManagement',
          query: {
            structId: this.structId,
          },
        })
      },
      // 每日血糖概览
      getDaySugar(date) {
        const params = {
          end: date + ' 23:59:59',
          start: date + ' 00:00:00',
        }
        getBGbyToken(params).then(res => {
          if (res.data.data && res.data.data.length) {
            // 平均葡萄糖水平
            this.dayAverageSuger = getaverage(formatData(res))
            this.dayMaxRange = getMaxRange(formatData(res))
            this.dayMaxSugar = getHigh(
              formatData(res),
              this.$route.query.targetHigh || localStorage.getItem('targetHigh')
            )
            this.dayMinSugar = getLow(formatData(res), this.$route.query.targetLow || localStorage.getItem('targetLow'))
            this.sugarNum = res.data.data.length
            this.sugarRate = ((this.sugarNum / 288) * 100).toFixed(0)
          } else {
            this.sugarNum = 0
            this.sugarRate = 0
            this.dayAverageSuger = 0
            this.dayMaxRange = 0
            this.dayMaxSugar = 0
            this.dayMinSugar = 0
          }
        })
      },
      // 前一天
      onClickLeft() {
        this.picLoading = true
        this.selectedDate = this.$moment(this.selectedDate).subtract(1, 'days').format('YYYY-MM-DD')
        this.startDate = this.$moment(this.startDate).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')
        this.endDate = this.$moment(this.endDate).subtract(1, 'days').format('YYYY-MM-DD HH:mm:ss')
        this.getBG14()
      },
      // 后一天
      onClickRight() {
        this.picLoading = true
        this.selectedDate = this.$moment(this.selectedDate).add(1, 'days').format('YYYY-MM-DD')
        this.startDate = this.$moment(this.startDate).add(1, 'days').format('YYYY-MM-DD HH:mm:ss')
        this.endDate = this.$moment(this.endDate).add(1, 'days').format('YYYY-MM-DD HH:mm:ss')
        this.getBG14()
      },
      // 打开自定义日期弹框
      showDialog() {
        this.showStart = true
      },
      // 保存自定义弹框
      confirmChoose() {
        this.selectedDate = this.$moment(this.defaultDate).format('YYYY-MM-DD')
        this.startDate = this.$moment(this.defaultDate).format('YYYY-MM-DD 00:00:00')
        this.endDate = this.$moment(this.defaultDate).format('YYYY-MM-DD 23:59:59')
        this.getBG14()
        this.showStart = false
      },
      //图片格式转换方法
      dataURLToBlob(dataurl) {
        const arr = dataurl.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new Blob([u8arr], { type: mime })
      },
      // 截屏方法
      saveImage(divText, imgText) {
        const canvasID = this.$refs[divText]
        html2canvas(canvasID).then(canvas => {
          const img = canvas.toDataURL('image/jpeg', 0.8).slice(23)
          const message = {
            message: '图片分享',
            body: {
              blob: img,
              type: 'report',
            },
          }
          const androidMessage = JSON.stringify(message)

          const phoneType = localStorage.getItem('phoneType')
          if (phoneType == 'Android') {
            window.android.saveImageToBlob(androidMessage)
          } else {
            window.webkit.messageHandlers.saveImageToBlob.postMessage(message)
          }
        })
      },
      getBG14() {
        this.loading = true
        const params = {
          end: this.endDate,
          start: this.startDate,
        }
        getBGbyToken(params).then(res => {
          this.SugerData = formatData(res)

          this.bg14 = res.data.data.map(v => {
            return { data: v.v, date: v.create_time }
          })
          this.handleBG()

          const percentileData = get24HourData(this.SugerData)
          this.perenctDiff = getLineChartData(percentileData)
          this.$nextTick(() => {
            if (this.$refs.VolumeChart1) {
              this.$refs.VolumeChart1.initChart()
            }
          })

          this.averageSuger = this.statisticalParameters[1][4] = getaverage(this.SugerData)
          this.estimatedSaccharification = getprotein(this.SugerData)
          this.standardError = this.statisticalParameters[3][4] = getstandard(this.SugerData, this.averageSuger)

          this.CV = getCV(this.averageSuger, this.standardError)
          this.GVI = getGVI(this.averageSuger, this.standardError)
          this.PGS = getPGS(this.SugerData)

          this.statisticalParameters[0][0] = '已读取'
          this.statisticalParameters[0][1] = getLow(this.SugerData)
          this.statisticalParameters[0][2] = getNormal(this.SugerData)
          this.statisticalParameters[0][3] = getHigh(this.SugerData)
          this.statisticalParameters[0][4] = getAllNum(this.SugerData)
          this.statisticalParameters[2][4] = getAllMid(this.SugerData)
          this.statisticalParameters[1][0] = '平均值'
          this.statisticalParameters[1][1] = getLowAvg(this.SugerData)
          this.statisticalParameters[1][2] = getNormalAvg(this.SugerData)
          this.statisticalParameters[1][3] = getHighAvg(this.SugerData)
          this.statisticalParameters[2][0] = '中位数'
          this.statisticalParameters[2][1] = getLowMid(this.SugerData) + ''
          this.statisticalParameters[2][2] = getNormalMid(this.SugerData)
          this.statisticalParameters[2][3] = getHighMid(this.SugerData)
          this.statisticalParameters[3][0] = '标准差'
          this.statisticalParameters[3][1] = getLowStandard(this.SugerData)
          this.statisticalParameters[3][2] = getNormalStandard(this.SugerData)
          this.statisticalParameters[3][3] = getHighStandard(this.SugerData)
          this.test = getSevenData(res)
        })
      },
      lastWeekBG() {
        const params = {
          end: this.lastWeekEndDate,
          start: this.lastWeekStartDate,
        }
        getBGbyToken(params).then(res => {
          this.SugerData2 = formatData(res)

          const percentileData = get24HourData(this.SugerData2)
          this.perenctDiff2 = getLineChartData(percentileData)

          this.$nextTick(() => {
            if (this.$refs.VolumeChart2) {
              this.$refs.VolumeChart2.initChart()
            }
          })

          this.averageSuger2 = this.statisticalParameters[1][4] = getaverage(this.SugerData2)
          this.estimatedSaccharification2 = getprotein(this.SugerData2)
        })
      },
      thisWeekBG() {
        const params = {
          end: this.thisWeekEndDate,
          start: this.thisWeekStartDate,
        }
        getBGbyToken(params).then(res => {
          this.SugerData3 = formatData(res)

          const percentileData = get24HourData(this.SugerData3)
          this.perenctDiff3 = getLineChartData(percentileData)

          this.$nextTick(() => {
            if (this.$refs.VolumeChart3) {
              this.$refs.VolumeChart3.initChart()
            }
          })

          this.averageSuger3 = this.statisticalParameters[1][4] = getaverage(this.SugerData3)
          this.estimatedSaccharification3 = getprotein(this.SugerData3)
        })
      },
      handleBG() {
        var T = 'YYYY-MM-DD HH:mm:ss'
        for (var i = 0; i < 14; i++) {
          var s = this.$moment().add(0, 'days').startOf('day')
          var e = this.$moment().add(1, 'days').startOf('day')
          var s1 = s.subtract(i, 'days')
          var e1 = e.subtract(i, 'days')
          this.daysArr.push({
            start: s1.format(T),
            end: e1.format(T),
            arr: [],
            title: s1.format('DD'),
          })
        }
        for (var j = 0; j < 2; j++) {
          var wstart = this.$moment().subtract(7 * (j + 1), 'days')
          var wend = this.$moment().subtract(7 * j, 'days')
          this.weeksArr.push({
            start: wstart.format(T),
            end: wend.format(T),
            arr: [],
          })
        }
        this.daysArr.reverse()
        this.checkBG()
      },
      checkBG() {
        var that = this

        this.bg14.forEach(v => {
          var t = v.date
          that.daysArr.forEach((v1, i1, arr1) => {
            if (v1.start <= t && t < v1.end) {
              arr1[i1].arr.push(v)
            }
          })
          that.weeksArr.forEach((v2, i2, arr2) => {
            if (v2.start <= t && t < v2.end) {
              arr2[i2].arr.push(v.data)
            }
          })
        })

        // 每周对比的数据及渲染
        that.thisWeeks = that.weeksArr[1].arr
        that.lastWeeks = that.weeksArr[0].arr

        this.loading = false
      },
      // 自定义时间转换
      formatDate(date) {
        return `${date.getYear() + 1900}-${date.getMonth() < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1}-${
          date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
        }`
      },
      // 初始化页面初始数据
      initialization() {
        const userInfo = this.$route.query
        this.scopeOfBloodSugar.high = userInfo.target_high ? userInfo.target_high : this.scopeOfBloodSugar.high
        this.scopeOfBloodSugar.low = userInfo.target_low ? userInfo.target_low : this.scopeOfBloodSugar.low
      },

      // drawPieChart() {
      //   // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
      //   const myChart = this.$echarts.init(document.getElementById('pieChartData'))
      //   const options = {
      //     legend: {
      //       orient: 'vertical',
      //       left: 'left',
      //       data: [],
      //     },
      //     series: [
      //       {
      //         hoverAnimation: false, //鼠标悬浮是否有区域弹出动画，false:无 true:有
      //         avoidLabelOverlap: false,
      //         type: 'pie',
      //         radius: '100%',
      //         label: {
      //           normal: {
      //             position: 'inner',
      //             show: true,
      //           },
      //         },
      //         data: this.pieChartData,
      //       },
      //     ],
      //   }
      //   // 使用刚指定的配置项和数据显示图表。
      //   myChart.setOption(options)
      // },
    },
  }
</script>
<style scoped lang="less">
  #reportCenter {
    box-sizing: border-box;
    min-height: 100vh;
    background: #e7e9f3;
    padding-bottom: 0.6rem;
    padding-top: 0.42rem;
    overflow: hidden;
  }

  .navigation {
    background: #d3eeff;
    width: 100vw;
    height: 0.48rem;
    line-height: 0.48rem;
    overflow: hidden;
    color: #7accff;
  }

  .tlrRadious {
    border-top-left-radius: 0.09rem;
    border-top-right-radius: 0.09rem;
  }

  .blrRadious {
    border-bottom-left-radius: 0.09rem;
    border-bottom-right-radius: 0.09rem;
  }

  .bacE8f6ff {
    background: #e8f6ff;
  }

  .bacFf7171 {
    background: #ff7171;
  }

  .bac65d172 {
    background: #65d172;
  }

  .bacFfd426 {
    background: #ffd426;
  }

  .colorTitle {
    color: #a2dafc;
  }

  .borderTop {
    border-top: 2px solid #fff;
  }

  .borderTopE8f6 {
    border-top: 2px solid #e8f6ff;
  }

  .w2h2fl {
    width: 20%;
    height: 100%;
    float: left;
    height: 0.24rem;
    line-height: 0.24rem;
  }

  .w3h3fl {
    width: 20%;
    height: 100%;
    float: left;
    height: 0.36rem;
    line-height: 0.36rem;
  }

  #echarts canvas {
    position: absolute;
    left: -35px;
    top: 9px;
    width: 340px;
    height: 180px;
  }

  .h15lh15 {
    height: 0.18rem;
    line-height: 0.18rem;
  }

  .h3lh3 {
    height: 0.36rem;
    line-height: 0.36rem;
  }

  .colorSelected {
    color: #7accff;
  }

  .dottedLine {
    width: 1px;
    height: 94%;
    border-right: 1px dotted #ccc;
    position: absolute;
    z-index: 9;
  }

  .dottedLiney {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #7accff;
    position: relative;
    z-index: 9;
  }

  .unSelectedDay {
    color: #7ecdff;
    background: #e8f6ff;
    width: 80px;
    line-height: 26px;
    font-size: 0.12rem;
    height: 26px;
    border-radius: 16px;
    margin: auto;
  }

  /* 盒子阴影样式 */
  .box-shadow {
    box-shadow: 2px 2px 2px 0px rgba(6, 14, 33, 0.16);
    padding: 0.06rem;
  }
  .none {
    display: none;
  }
  .show {
    margin-top: 0.08rem;
    display: block;
  }
  [class^='bloodRate'] {
    min-height: 3px;
    margin-top: 1px;
    border-radius: 2px;
  }
  .tip {
    margin: 0 auto;
    width: 100%;
    height: 0.44rem;
    background-color: #fff;
    overflow: hidden;
  }
  .btns {
    box-sizing: border-box;
    padding: 0 0.04rem;
    margin: 0.03rem auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 3.33rem;
    height: 0.38rem;
    background-color: #f1f1f1;
    border-radius: 0.06rem;
  }
  .btns > span {
    display: block;
    width: 0.67rem;
    height: 0.26rem;
    font-size: 0.13rem;
    text-align: center;
    line-height: 0.26rem;
    color: #292929;
    // border-right: 1px solid #c3c3c3;
    &:last-of-type {
      border-right: 0;
    }
  }
  .active {
    height: 0.32rem !important;
    line-height: 0.32rem !important;
    background-color: #fff;
    border-radius: 0.06rem;
    border-right: 0 !important;
  }
  .iActive {
    color: #adb1b6 !important;
  }
  .popup-header {
    display: flex;
    justify-content: space-between;
    padding: 16px;
  }

  .picker-container {
    display: flex; /* 设置为flex布局 */
    padding: 16px;
    justify-content: space-between; /* 确保左右对齐 */
  }

  .picker-item {
    flex: 1; /* 每个picker占用相等的空间 */
    margin-right: 8px; /* 右侧添加间隔 */
  }

  .picker-item:last-child {
    margin-right: 0; /* 最后一个picker去掉右边间隔 */
  }
  .averSugar {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0.19rem 0 0 0.14rem;
    width: 3.55rem;
    height: 1.7rem;
    background: #ffffff;
    border-radius: 6px;
    .sugarWord {
      margin-bottom: 0.08rem;
      text-align: left;
      font-size: 0.1rem;
      color: #848894;
      height: 0.12rem;
      line-height: 0.12rem;
    }
    .sugar1 {
      margin-bottom: 0.16rem;
      text-align: left;
      font-size: 0.45rem;
      color: #292929;
      height: 0.48rem;
      line-height: 0.48rem;
      span {
        font-size: 0.1rem;
        color: #848894;
      }
    }
    .sugar2 {
      text-align: left;
      font-size: 0.29rem;
      color: #292929;
      height: 0.48rem;
      line-height: 0.48rem;
      span {
        font-size: 0.1rem;
        color: #848894;
      }
    }
  }

  .threeData {
    box-sizing: border-box;
    display: flex;
    background-color: #fff;
    width: 3.55rem;
    height: 0.81rem;
    margin: 0.08rem auto 0;
    border-radius: 0.06rem;
    padding: 0.18rem 0 0.13rem 0;
    color: #848894;
    & > div {
      padding-left: 0.08rem;
      align-items: flex-start;
      justify-content: space-between;
      text-align: left;
      width: 25%;
      height: 0.5rem;
      border-right: 1px solid #e7e9f3;
      &:last-child {
        border-right: 0;
      }
      .tTop {
        font-size: 0.1rem;
      }
      .tMid {
        height: 0.11rem;
        color: #292929;
        font-size: 0.13rem;
        span {
          font-size: 0.08rem;
        }
      }
      .tBot {
        font-size: 0.08rem;
      }
    }
  }
  .navTitle {
    position: fixed;
    z-index: 99;
    top: 0;
    width: 100%;
    background-color: #fff;
    height: 0.42rem;
    line-height: 0.42rem;
    text-align: center;
    color: #009944;
  }
  .with-shadow {
    box-shadow: 0px 1px 3px 0px rgba(215, 215, 215, 0.65);
  }
  .sugarMap {
    margin: 0.08rem auto 0;
    width: 3.55rem;
    height: 2rem;
    border-radius: 0.06rem;
    background-color: #fff;
    overflow: hidden;
    .sugar-top {
      margin: 0.1rem 0 0 0.13rem;
      img {
        margin-right: 0.05rem;
        width: 0.18rem;
        height: 0.18rem;
      }
      span {
        font-size: 0.13rem;
        color: #848894;
      }
    }
    .bar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 0.12rem;
      font-size: 0.08rem;
      color: #000;
      .one {
        margin-right: 0.2rem;
        span {
          margin-bottom: 0.03rem;
          width: 0.3rem;
          height: 0;
          border-top: 0.03rem solid #000;
        }
      }
      .two {
        margin-right: 0.2rem;
        span {
          margin-bottom: 0.03rem;
          width: 0.4rem;
          height: 0;
          border-top: 0.05rem solid #0db8fc;
        }
      }
      .three {
        margin-right: 0.2rem;
        span {
          margin-bottom: 0.03rem;
          width: 0.4rem;
          height: 0;
          border-top: 0.05rem solid #b3e4fb;
        }
      }
      .four {
        margin-right: 0.2rem;
        span {
          margin-bottom: 0.03rem;
          width: 0.4rem;
          height: 0.04rem;
          border: 0.014rem solid #0db8fc;
        }
      }
    }
  }
  .dateChose {
    ::v-deep .vanBar {
      position: relative;
      height: 0.43rem;
      .van-nav-bar__content {
        height: 0.43rem !important;
        .van-nav-bar__left,
        .van-nav-bar__right {
          margin-left: 0.22rem;
          width: 0.23rem;
          height: 0.43rem;

          padding: 0;
          .van-icon {
            margin: 0;
            width: 0.23rem;
            height: 0.23rem;
            text-align: center;
            line-height: 0.23rem;
            background-color: #f1f1f1;
            border-radius: 50%;
            font-size: 0.16rem;
            text-align: center;
            color: #292929;
            &::before {
              margin-right: 0.02rem;
            }
          }
        }
        .van-nav-bar__right {
          margin: 0 0.22rem 0 0;
          .van-icon {
            &::before {
              margin: 0 0 0 0.03rem;
            }
          }
        }
        .van-nav-bar__title {
          height: 0.43rem;
          line-height: 0.43rem;
          & > div {
            display: flex;
            align-items: center;
            padding: 0 !important;
            background-color: #fff !important;
            color: #292929 !important;
            font-size: 0.12rem !important;
            .van-icon {
              margin-left: 0.1rem;
              color: #292929 !important;
              font-size: 0.17rem !important;
            }
          }
        }
      }
    }
  }
  .weeksApper {
    box-sizing: border-box;
    margin: 0.08rem auto;
    padding: 0.1rem 0 0 0.13rem;
    width: 3.55rem;
    height: 2.15rem;
    border-radius: 0.06rem;
    background-color: #fff;
    .weeks-top {
      img {
        margin-right: 0.05rem;
        width: 0.18rem;
        height: 0.18rem;
      }
      span {
        font-size: 0.13rem;
        color: #848894;
      }
    }
    .apper {
      margin-top: 0.08rem;
      display: flex;
      .a-left,
      .a-right {
        box-sizing: border-box;
        padding-left: 0.03rem;
        width: 1.64rem;
        height: 1.7rem;
        background: #f6f7f9;
        border-radius: 0.05rem;
        .dayShow {
          margin-top: 0.06rem;
          margin-left: 0.1rem;
          text-align: left;
          color: #7d7877;
          span {
            margin-right: 0.05rem;
            font-size: 0.11rem;
            font-weight: bold;
          }
          i {
            font-size: 0.08rem;
            font-weight: bold;
          }
        }
        .averSugar-s {
          margin-top: 0.02rem;
          width: 1.64rem;
          .sugarWord {
            margin-bottom: 0.04rem;
            text-align: left;
            font-size: 0.07rem;
            color: #848894;
          }
          .sugar1 {
            text-align: left;
            font-size: 0.2rem;
            font-weight: bold;
            color: #000;
            span {
              font-size: 0.07rem;
              color: #848894;
            }
          }
          .sugar2 {
            text-align: left;
            font-size: 0.13rem;
            font-weight: bold;
            color: #000;
            span {
              font-size: 0.06rem;
              color: #848894;
            }
          }
        }
      }
      .a-right {
        margin-left: 0.06rem;
      }
    }
  }
  .topData {
    margin-top: -0.01rem;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    padding-left: 0.22rem;
    width: 3.75rem;
    height: 0.52rem;
    background-color: #fff;
    .data-l {
      height: 100%;
      font-size: 0.1rem;
      color: #292929;
      & > div {
        align-items: flex-start;
        margin-left: 0.08rem;
        width: 0.7rem;
        border-right: 1px solid #e7e9f3;
        &:nth-of-type(3) {
          border-right: 0;
        }
        span {
          margin-bottom: 0.1rem;
          display: block;
          color: #848894;
        }
      }
    }
    .export {
      margin-left: 0.2rem;
      width: 0.68rem;
      height: 0.32rem;
      background: #009944;
      border-radius: 0.06rem;
      font-size: 0.12rem;
      color: #fff;
      line-height: 0.32rem;
    }
  }
  .daySugar {
    width: 3.55rem;
    height: 2.3rem;
    background-color: #fff;
    border-radius: 0.06rem;
    margin: 0.08rem auto;
    overflow: hidden;
    .sugar-top {
      margin: 0.1rem 0 0 0.13rem;
      img {
        margin-right: 0.05rem;
        width: 0.18rem;
        height: 0.18rem;
      }
      span {
        font-size: 0.13rem;
        color: #848894;
      }
    }
  }
  ::v-deep .van-ellipsis {
    line-height: 14px;
  }
  .van-picker::before {
    content: '';
    position: absolute;
    top: 110px;
    left: 0;
    width: 100%;
    height: 44px;
    background-color: rgba(210, 212, 216, 0.5);
  }
</style>
