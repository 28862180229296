<template>
  <div class="mineView">
    <div class="title flex-b">
      <div style="margin-left: 0.16rem; color: #009944">我的</div>
      <div>
        <img src="@/static/pic/jingbao.png" style="width: 0.17rem; height: 0.19rem" @click="openAlarmPage()" />
        <img
          src="@/static/pic/saomiao.png"
          style="margin: 0 0.15rem 0 0.11rem; width: 0.2rem; height: 0.2rem"
          @click="openCamera()"
        />
      </div>
    </div>
    <div class="head" @click="showEditDalog">
      <div class="left">
        <div class="pic">
          <div class="avatar">
            <img :src="pic1" alt="" v-if="pic1" />
            <img src="https://i.postimg.cc/2SrXwLXR/litangbao.png" alt="" v-else />
          </div>
          <div class="name flex-c">
            <span>{{ userName }}</span>
            <span style="font-size: 0.1rem">{{ userPhone }}</span>
          </div>
        </div>
        <div class="days">
          已陪伴您
          <span style="color: #009944">{{ bgDays }}</span>
          个日夜
        </div>
      </div>
      <div class="right">
        <img src="@/static/pic/qrIcon.png" alt="" style="width: 0.19rem; height: 0.18rem" />
        <img
          src="@/static/pic/rightRole.png"
          alt=""
          style="width: 0.11rem; height: 0.13rem; margin: 0 0.23rem 0.02rem 0.06rem"
        />
      </div>
    </div>
    <div class="content">
      <div class="row" @click="showCode(2)">
        <img src="@/static/pic/book.png" class="img-l" />
        <div class="con-title flex-b">
          <span>电子病历</span>
          <img src="@/static/pic/rightRole.png" class="img-r" />
        </div>
      </div>
      <div class="row" @click="openConfig()">
        <img src="@/static/pic/setBook.png" class="img-l" />
        <div class="con-title flex-b">
          <span>配置文件</span>
          <img src="@/static/pic/rightRole.png" class="img-r" />
        </div>
      </div>
      <div class="row" @click="openCGMView">
        <img src="@/static/pic/deviceIcon.png" class="img-l" />
        <div class="con-title flex-b">
          <span>我的设备</span>
          <img src="@/static/pic/rightRole.png" class="img-r" />
        </div>
      </div>
      <div class="row" @click="GJcode()">
        <img src="@/static/pic/authCode.png" class="img-l" />
        <div class="con-title flex-b">
          <span>硅基授权码</span>
          <img src="@/static/pic/rightRole.png" class="img-r" />
        </div>
      </div>
      <div class="row" @click="$router.push('sijoyService')">
        <img src="@/static/pic/serveBook.png" class="img-l" />
        <div class="con-title flex-b">
          <span>服务协议</span>
          <img src="@/static/pic/rightRole.png" class="img-r" />
        </div>
      </div>
      <div class="row" @click="$router.push('sijoyPrivacy')">
        <img src="@/static/pic/primary.png" class="img-l" />
        <div class="con-title flex-b">
          <span>隐私政策</span>
          <img src="@/static/pic/rightRole.png" class="img-r" />
        </div>
      </div>
      <div class="row" @click="$router.push('updateApp')" v-show="!(isIos == 'ios')">
        <img src="@/static/pic/update.png" class="img-l" />
        <div class="con-title flex-b">
          <span>软件更新</span>
          <img src="@/static/pic/rightRole.png" class="img-r" />
        </div>
      </div>
      <div class="row" @click="loginOut">
        <img src="@/static/pic/logOut.png" class="img-l" />
        <div class="con-title flex-b">
          <span>退出登录</span>
          <img src="@/static/pic/rightRole.png" class="img-r" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Toast } from 'vant'
  import EditDialog from './component/editDialog.vue'
  import selectHospital from './component/selectHospital.vue'
  import qrcCode from '../personnelManagement/components/qrcCode.vue'
  import { calculate } from '../sugerData/getSugerData'
  import { getLoopOption, getHospitalByDoctor, getHospitalByPatient, loopHistory } from '@/api/relationship'
  import { loginOUT, loginOff } from '@/api/userServices'
  import { getStructIdByPatientId, getUrlByStructId } from '@/api/admin'
  export default {
    name: 'MineView',
    components: { EditDialog, selectHospital, qrcCode },
    data() {
      return {
        showSelectHospital: false,
        pic1: '',
        showEditDialog: false,
        userName: '',
        userPhone: '',
        userAddr: '',
        showChageRole: false,
        columns: ['主任', '医生', '患者'],
        showQRCCOde: false,
        showHint: false,
        bgDays: '',
        hospitalDialog: false,
        hospitalList: [],
        version: localStorage.getItem('version'),
        cgmType: JSON.parse(localStorage.getItem('appuser')).cgm,
        qrcType: null,
        isCases: '',
        hospital: null,
        shopShow: false,
        jsShow: false,
        isIos: localStorage.getItem('phoneType'),
      }
    },

    mounted() {
      window.waitIOSCamera = this.waitIOSCamera
      window.waitAndroidCamera = this.waitAndroidCamera
      this.hospital = localStorage.getItem('hospital')
      this.flashUserInfo()
      window.loginoutTojs = this.loginoutTojs
    },

    methods: {
      // 扫一扫
      openCamera() {
        const message = {
          message: '扫描二维码',
          body: {},
        }
        const androidMessage = JSON.stringify(message)
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.openCamera(androidMessage)
        } else {
          window.webkit.messageHandlers.openCamera.postMessage(message)
        }
      },
      waitIOSCamera(codeInfo) {
        console.log(codeInfo, 'codeInfo')
        if (codeInfo.qrcType == 1) {
          Toast('您扫描的不是医生二维码！')
        } else if (codeInfo.qrcType == 2) {
          // 患者扫医生码加入医院并和这个医生绑定关系
          this.$router.push({
            path: 'bindingInfo',
            query: codeInfo,
          })
        } else if (
          codeInfo.qrcType == 3 || // 医生扫医院码加入医院
          codeInfo.qrcType == 4 || // 患者扫医院码加入医院
          codeInfo.qrcType == 5 // 患者扫医院码加入医院并加入护士站
        ) {
          console.log(codeInfo.qrcType, 'qrcType')
          this.$router.push({
            path: 'joinHospital',
            query: codeInfo,
          })
        }
      },
      waitAndroidCamera(info) {
        const codeInfo = JSON.parse(JSON.stringify(info))
        if (codeInfo.qrcType == 1) {
          Toast('您扫描的不是医生二维码！')
        } else if (codeInfo.qrcType == 2) {
          // 患者扫医生码加入医院并和这个医生绑定关系
          this.$router.push({
            path: 'bindingInfo',
            query: codeInfo,
          })
        } else if (
          codeInfo.qrcType == 3 || // 医生扫医院码加入医院
          codeInfo.qrcType == 4 || // 患者扫医院码加入医院
          codeInfo.qrcType == 5 // 患者扫医院码加入医院并加入护士站
        ) {
          console.log(codeInfo.qrcType, 'qrcType')
          this.$router.push({
            path: 'joinHospital',
            query: codeInfo,
          })
        }
        return '确定'
      },
      openAlarmPage() {
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.openNotification()
        }
        this.$router.push('BloodSugar/alarmCFG')
      },
      toMyNs() {
        this.$router.push('/nsPage')
      },
      GJcode() {
        this.$router.push('/GJcode')
      },
      openConfig() {
        const params = {
          uid: JSON.parse(localStorage.getItem('appuser')).id,
          create_time: this.$moment().add(1, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
          type: JSON.parse(localStorage.getItem('appuser')).loopRemote,
        }
        loopHistory(params).then(({ data }) => {
          if (data.Profile) {
            this.$router.push({
              path: '/configFile',
            })
          } else {
            this.$toast('尚未设置配置文件')
          }
        })
      },
      openPersonnel() {
        const appuser = localStorage.getItem('appuser')
        const params = {
          doctorId: JSON.parse(appuser).id,
          status: 1,
        }
        getHospitalByDoctor(params).then(res => {
          if (res.data.data && res.data.data.length > 0) {
            this.$router.push({
              path: '/hospitalList',
            })
            localStorage.setItem('comePath', 'myPatient')
          } else {
            Toast('您未绑定医院，暂无医生权限！')
          }
        })
      },
      // loop闭环操作
      toLoopHandle() {
        const uid = JSON.parse(localStorage.getItem('appuser')).id
        getLoopOption({ uid: uid }).then(data => {
          console.log(data)
          if (data.data) {
            localStorage.setItem('loopOptions', JSON.stringify(data.data))
            this.$router.push('/MineView/loopHandle')
          } else {
            this.showHint = true
          }
        })
      },
      // 我的二维码
      showCode(num) {
        if (num == 1) {
          // this.isCases = false;
          // const params = {
          //   doctorId: JSON.parse(localStorage.getItem("appuser")).id,
          //   status: 1,
          // };
          // getHospitalByDoctor(params).then((res) => {
          //   if (res.data.data && res.data.data.length > 0) {
          //     this.hospitalList = res.data.data;
          //     this.hospitalDialog = true;
          //     this.qrcType = 2;
          //   } else {
          //     this.showQRCCOde = true;
          //     this.qrcType = 1;
          //   }
          // });
          const structId = localStorage.getItem('hospital')
            ? JSON.parse(localStorage.getItem('hospital')).struct_id
            : null
          if (structId) {
            this.qrcType = 2
            this.showQRCCOde = true
          } else {
            this.qrcType = 1
            this.showQRCCOde = true
          }
        } else {
          const appuser = JSON.parse(localStorage.getItem('appuser'))
          getStructIdByPatientId({ patientId: appuser.id }).then(res => {
            if (res.data.code == 200 && res.data.data.structId) {
              this.$router.push({
                path: '/patientCases',
                query: {
                  structId: res.data.data.structId,
                  patientId: JSON.parse(localStorage.getItem('appuser')).id,
                  name: JSON.parse(localStorage.getItem('appuser')).name,
                  isEdit: 1,
                },
              })
            } else {
              this.$toast('请确保已经绑定医院')
            }
          })
        }
      },
      // 选择医院
      hospitalClick(item) {
        if (this.isCases) {
          this.$router.push({
            path: '/patientCases',
            query: {
              structId: item.struct_id,
              patientId: JSON.parse(localStorage.getItem('appuser')).id,
              name: JSON.parse(localStorage.getItem('appuser')).name,
              isEdit: 1,
            },
          })
        } else {
          localStorage.setItem('hospital', JSON.stringify(item))
          this.hospitalDialog = false
          this.showQRCCOde = true
        }
      },
      openAlarm() {
        const phoneType = localStorage.getItem('phoneType')
        if (phoneType == 'Android') {
          window.android.openNotification()
        }
        this.$router.push('/BloodSugar/alarmCFG')
      },
      // structConfig () {
      //   this.$router.push('/MineView/adminMain')
      // },
      closeSelectHospital() {
        this.showSelectHospital = false
      },
      openCGMView() {
        this.$router.push('/CGMDetails')
      },
      openShop() {
        const appuser = JSON.parse(localStorage.getItem('appuser'))
        getStructIdByPatientId({ patientId: appuser.id }).then(res => {
          if (res.data.code == 200 && res.data.data.structId) {
            let structId = res.data.data.structId

            getUrlByStructId({ structId: structId }).then(result => {
              this.reportUrl =
                (result.data.data.shopUrl ? result.data.data.shopUrl : 'https://shop.cgm.go-sugar.cn/') +
                '?uid=' +
                appuser.id +
                '&shopHelper=' +
                result.data.data.shopHelper +
                '&apiUrl=' +
                (result.data.data.apiUrl ? result.data.data.apiUrl : 'https://shop.cgm.go-sugar.cn/shop/')

              window.parent.location.href = this.reportUrl
            })
          } else {
            this.reportUrl =
              'https://shop.cgm.go-sugar.cn/?uid=' +
              appuser.id +
              '&shopHelper=https://work.weixin.qq.com/kfid/kfc56e640a9f767f459' +
              '&apiUrl=https://shop.cgm.go-sugar.cn/shop/'

            window.parent.location.href = this.reportUrl
          }
        })
      },
      // 技术支持
      openJs() {
        this.jsShow = true
      },
      // goBackMineView() {
      //   this.showEditDialog = false;
      //   this.flashUserInfo();
      // },
      showEditDalog() {
        this.$router.push('mineEdit')
        // this.showEditDialog = true;
      },
      openEmpTree() {
        this.$router.push('/SmartTree')
      },

      // 医生认证
      openAuthentication() {
        this.$router.push('/authentication')
      },
      // 推广员
      openPromoter() {
        const appuser = localStorage.getItem('appuser')
        if (JSON.parse(appuser).role) {
          this.$router.push('/promoter')
        } else {
          Toast('您暂无推广员权限！')
        }
      },
      flashUserInfo() {
        const user = JSON.parse(localStorage.getItem('appuser'))
        this.pic1 = !user.images || user.images == '未设置' ? null : user.images
        this.userName = user.name
        this.userPhone = user.iphone
        this.userAddr = user.city

        const endDate = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.bgDays = calculate(user.createdate, endDate)
      },
      loginOut() {
        this.$dialog
          .confirm({
            title: '退出登录',
            message: '您确定要退出理糖宝吗?',
          })
          .then(() => {
            let phoneFlag = navigator.userAgent.match(
              /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
            )
            if (phoneFlag) {
              const appuser = localStorage.getItem('appuser')
              const params = {
                id: JSON.parse(appuser).id,
              }
              loginOUT(params).then(res => {
                if (res.data.code == 200) {
                  localStorage.removeItem('appuser')
                  localStorage.removeItem('hospital')
                  localStorage.setItem('loginStatus', false)
                  localStorage.removeItem('token', false)
                  this.$router.push('/login')

                  const message = {
                    message: '退出登录',
                    body: {},
                  }
                  const androidMessage = JSON.stringify(message)
                  const phoneType = localStorage.getItem('phoneType')
                  if (phoneType == 'Android') {
                    window.android.loginoutTojs(androidMessage)
                  } else {
                    window.webkit.messageHandlers.loginoutTojs.postMessage(message)
                  }
                }
              })
            } else {
              localStorage.removeItem('appuser')
              localStorage.removeItem('hospital')
              localStorage.setItem('loginStatus', false)
              localStorage.removeItem('token', false)
              this.$router.push('/login')

              const message = {
                message: '退出登录',
                body: {},
              }
              const androidMessage = JSON.stringify(message)
              const phoneType = localStorage.getItem('phoneType')
              if (phoneType == 'Android') {
                window.android.loginoutTojs(androidMessage)
              } else {
                window.webkit.messageHandlers.loginoutTojs.postMessage(message)
              }
            }
          })
          .catch(() => {
            // on cancel
          })
      },
      logOff() {
        this.$dialog
          .confirm({
            title: '注销账户',
            message: '您确定要注销账户吗?如需找回，请联系客服！',
          })
          .then(() => {
            const appuser = localStorage.getItem('appuser')
            const params = {
              uid: JSON.parse(appuser).id,
            }
            loginOff(params).then(res => {
              if (res.data.code == 200) {
                localStorage.removeItem('appuser')
                localStorage.removeItem('hospital')
                localStorage.setItem('loginStatus', false)
                localStorage.removeItem('token', false)
                this.$router.push('/login')
              }
            })
          })
          .catch(() => {
            // on cancel
          })
      },
      chageRole() {
        this.showChageRole = true
      },
      onConfirm(value, index) {
        Toast(`当前值：${value}, 当前索引：${index}`)
        if (index === 1) {
          this.showChageRole = false
          this.showSelectHospital = true
        }
      },
      onChange(picker, value, index) {
        Toast(`当前值：${value}, 当前索引：${index}`)
      },
      onCancel() {
        Toast('取消')
      },
    },
  }
</script>

<style scoped lang="less">
  .mineView {
    color: #292929;
    min-height: 100vh;
    background-color: #e7e9f3;
    padding-bottom: 0.65rem;
    .title {
      width: 100%;
      height: 0.62rem;
      background-color: #fff;
    }
    .head {
      height: 1.1rem;
      width: 100%;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      .left {
        .pic {
          display: flex;
          height: 0.65rem;
          .avatar {
            margin: 0.03rem 0.11rem 0.16rem 0.31rem;
            width: 0.64rem;
            height: 0.64rem;
            border-radius: 50%;
            overflow: hidden;
            img {
              width: 0.64rem;
              height: 0.64rem;
            }
          }
          .name {
            padding: 0.14rem 0;
            align-items: flex-start;
            justify-content: space-between;
          }
        }
        .days {
          color: #848894;
          font-size: 0.12rem;
          margin-left: 0.06rem;
          margin-top: 0.16rem;
        }
      }
      .right {
        margin-top: 0.26rem;
      }
    }
    .content {
      width: 3.55rem;
      margin-left: 0.1rem;
      margin-top: 0.11rem;
      border-radius: 0.06rem;
      overflow: hidden;
      .row {
        display: flex;
        align-items: center;
        height: 0.54rem;
        background-color: #fff;
        .con-title {
          border-bottom: 1px solid #e8e8e8;
          width: 100%;
          height: 100%;
          font-size: 0.13rem;
        }
      }
      .img-l {
        margin: 0 0.21rem 0 0.2rem;
        width: 0.18rem;
        height: 0.18rem;
      }
      .img-r {
        margin-right: 0.13rem;
        width: 0.11rem;
        height: 0.13rem;
      }
    }
  }
</style>
