<template>
  <div class="common-box">
    <!-- 散点图 -->
    <van-loading v-if="picLoading" />
    <div :id="title" class="chart1" v-else></div>
  </div>
</template>
<script>
  import { Line } from '@antv/g2plot'
  import { getBGbyToken, getSugerbyId } from '@/api/userServices'
  export default {
    props: {
      userId: {
        type: String,
        default: '',
      },
      title: {
        type: String,
        default: 'chart1',
      },
      endDays: {
        type: String,
        default: '0',
      },
      startDays: {
        type: String,
        default: '6',
      },
      isShow: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        scatterPlot: null,
        chartData: [],
        // 时间段选择
        time: 3,
        averageSuger: '',
        standardError: '',
        CV: '',
        sugarNum: '',
        overRange: '',
        picLoading: true,
      }
    },
    created() {
      this.getDateArray()
      setTimeout(() => {
        this.picLoading = false
        this.$nextTick(() => {
          this.getChart()
        })
      }, 300)
    },
    mounted() {},
    methods: {
      getChart() {
        let data = this.chartData

        // 判断数据中是否包含今天或昨天的数据
        const hasToday = data.some(item => item.day === '今天')
        const hasYesterday = data.some(item => item.day === '昨天')

        let colors = []
        if (hasToday && hasYesterday) {
          colors = ['#D3D3D3', '#009944'] // 昨天灰色，今天绿色
        } else if (hasToday) {
          colors = ['#009944'] // 只有今天，绿色
        } else if (hasYesterday) {
          colors = ['#D3D3D3'] // 只有昨天，灰色
        }

        this.scatterPlot = new Line(this.title, {
          data,
          xField: 'create_time',
          yField: 'v',
          seriesField: 'tooltip_time',
          // connectNulls: false,
          smooth: true,
          lineStyle: {
            lineWidth: 1.4, // 设置线条的宽度为 1
          },
          color: colors, // 今天是绿色，昨天是灰色
          state: {
            active: {
              style: {
                lineWidth: 1, // 选中曲线时的宽度
              },
            },
          },
          xAxis: {
            type: 'time',
            max: this.maxTime,
            min: this.minTime,
            mask: 'HH:mm', // 显示格式为小时:分钟
            tickMethod: scale => {
              const { min, max, tickCount } = scale
              const ticks = []
              for (let i = min; i <= max; i += 3 * 60 * 60 * 1000) {
                ticks.push(i)
              }
              return ticks
            },
          },
          yAxis: {
            line: null,
            tickMethod: () => [0, 5, 10, 15, 20, 25],
          },
          legend: {
            visible: false,
          },

          // @TODO 后续会换一种动画方式
          animation: {
            appear: {
              animation: 'path-in',
              duration: 1400,
            },
          },
          annotations: [
            {
              type: 'region',
              // start: ['min', this.targetLow],
              // end: ['max', this.targetHigh],
              start: ['min', 3.9],
              end: ['max', 10],
              style: {
                stroke: '#edf0f2',
                lineDash: [2, 2],
              },
            },

            {
              type: 'text',
              position: ['min', 10],
              content: 10,
              // position: ['min', this.targetHigh],
              // content: this.targetHigh,
              style: {
                fill: '#F4664A',
              },
            },

            {
              type: 'text',
              // position: ['min', this.targetLow],
              // content: this.targetLow,
              content: 3.9,
              position: ['min', 3.9],
              style: {
                fill: '#ffaa29',
              },
            },
            // {
            //   type: 'regionFilter',
            //   start: ['min', 0],
            //   end: ['max', 3.9],
            //   color: '#ffaa29',
            // },
            // {
            //   type: 'regionFilter',
            //   start: ['min', 10],
            //   end: ['max', 20],
            //   color: '#F4664A',
            // },
          ],
        })
        this.scatterPlot.render()
      },

      getDateArray() {
        this.chartData = []
        const params = {
          end: this.$moment().format('YYYY-MM-DD 23:59:59'),
          start: this.$moment().subtract(1, 'days').format('YYYY-MM-DD 00:00:00'),
        }
        getBGbyToken(params).then(res => {
          const today = this.$moment().format('YYYY-MM-DD')
          if (res.data.data && res.data.data.length) {
            res.data.data.forEach(item => {
              const date = item.create_time.substring(0, 10) // 提取日期部分
              const day = date === today ? '今天' : '昨天'

              this.chartData.push({
                create_time: today + ' ' + item.create_time.substring(11, 16),
                v: item.v,
                tooltip_time: item.create_time.substring(5, 10),
                day: day,
              })
            })
          }
        })
      },
    },
    computed: {
      maxTime() {
        const nextTime = this.$moment().add(1, 'days').format('YYYY-MM-DD 00:00:00')
        return new Date(nextTime).getTime()
      },
      minTime() {
        const min = this.$moment().format('YYYY-MM-DD 00:00:00')
        return new Date(min).getTime()
      },
    },
    watch: {
      chartData: {
        handler: function (newVal, oldVal) {
          if (this.scatterPlot) {
            this.$nextTick(() => {
              this.scatterPlot.destroy()
              this.getChart()
            })
          }
        },
      },
    },
  }
</script>
<style scoped lang="less">
  .common-box {
    width: 3.55rem;
    margin: 0 auto;
    position: relative;
  }
  .van-radio-group--horizontal {
    justify-content: center;
  }
  .chartCan {
    width: 100%;
    background: #d3eeff;
    position: absolute;
    border-top: 2px dotted #c1b8b8;
    border-bottom: 2px dotted #c1b8b8;
    height: 0.045rem;
    bottom: 0.094rem;
  }
  .bottom-num {
    position: absolute;
    right: 5px;
    bottom: -16px;
    color: red;
  }
  .top-num {
    position: absolute;
    right: 5px;
    top: -16px;
    color: orange;
  }
  .unit {
    position: absolute;
    left: 5px;
    margin-top: 5px;
  }
  .van-radio__label {
    color: #7accff;
  }
  .chart1 {
    width: 100%;
    height: 2.4rem;
  }
  .chart2 {
    width: 450px;
    height: 1.6rem;
  }
  .title {
    display: flex;
    align-items: center;
    font-size: 0.13rem;
    height: 0.4rem;
    color: #848894;
    font-weight: normal;
    img {
      margin: 0 0.05rem 0 0.11rem;
      width: 0.18rem;
      height: 0.18rem;
    }
    span {
      height: 0.4rem;
      line-height: 0.4rem;
    }
  }
  .van-loading {
    height: 2.5rem;
    .van-loading__spinner {
      margin-top: 1.2rem;
    }
  }
</style>
